import React, { PropsWithChildren, useEffect, useState } from "react";

import { Trans } from "react-i18next";
import { ModalContainer, RequestInputBox, DialogBox, Backdrop, DialogCheckBox, DialogInput,
   DialogInputText, StyledDownloadPolicyLink, StyledUnderline, DialogPolicyBox, DialogBorder,
    PolicySpanText, InputBox, ModalRequestBtn, ModalTitleBox, InputGrid } from "./ModalRequest.style";

import { ModalTextBox} from "../Modal/ModalDownload.style";
import { StyledDisplay, StyledLabel, StyledParagraph } from "@components/Text";

import { IconCheckBoxBlack, IconCheckBoxEmpty} from "@assets/images/icon";
import emailjs from 'emailjs-com';

import modalRequest from "@src/lang/ko/apartment/modalRequest";
import modalEvent from "@src/lang/ko/apartment/modalEvent";
import { DialogInputBoxRequest, DialogInputRequest } from "../Modal/Modal2.style";
import modal from "@src/lang/ko/modal";
import { Spacing, SpanBlue, SpanBlueUnderline } from "@pages/Apartment/Apartment.style";
import { LabelFFF } from "@layouts/Default/components/NavBox/NavBox.style";
import { PolicySpanTextBlue } from "@pages/Apartment/ModalEvent/ModalEvent.style";

interface ModalDefaultType {
  onClickToggleModal: () => void;
}




function ModalRequest({
  onClickToggleModal,
}: PropsWithChildren<ModalDefaultType>) {


  const [focus, setFocus] = useState({
    apart: false,
    name: false,
    phone: false,
    email: false,
    request: false

  });

  const handleFocus = (field) => {
    setFocus((prevFocus) => {
      const updatedFocus = {} as {
        apart: boolean;
        name: boolean;
        phone: boolean;
        email: boolean;
        request: boolean;
      };
      for (const key in prevFocus) {
        updatedFocus[key] = key === field;
      }
      return updatedFocus;
    });
  };

  const handleKeyDown = (e, currentField) => {
    if (e.key === "Tab") {
      e.preventDefault();

      const fields = ["email"];
      const currentFieldIndex = fields.indexOf(currentField);
      const nextFieldIndex = currentFieldIndex === fields.length - 1 ? 0 : currentFieldIndex + 1;
      const nextField = fields[nextFieldIndex];

      const nextInputElement = document.getElementById(`${nextField}-input`);
      if (nextInputElement) {
        nextInputElement.focus();
      }
    }
  };


  const [apart, setApart] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [request, setRequest] = useState<string>('');

  const [checked, setChecked] = useState(false);
  const [submit, setSubmit] = useState(false);


  const onSubmit = () => {

    if(!apart){
      alert('아파트 이름을 입력해주세요.');
    } else if(!phone){
      alert('전화번호를 입력해주세요.');
    } else if(!email){
      alert('이메일을 입력해주세요.');
    } else if(email && (!email.includes('@') || !email.includes('.') )) {
      alert('이메일 형식에 맞게 입력해주세요.');
    } else if (checked && email.includes('@') && email.includes('.')) {
      //이메일 보내기
      onSendEmail();
      //창 닫기
      onClickToggleModal();
    } else {
      alert('필수 방침에 동의해주세요.');
    }

  }
  const onSendEmail = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      apartment : apart,
      name : name,
      phone : phone,
      email: email,
      request : request
    }

    emailjs.send('service_7yv5b7d', `template_97zj36e`, mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
        alert("정상적으로 문의가 접수되었습니다. 빠른 시일 내로 연락드리겠습니다.");
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });

  }

  const policyCheck = () => {
    setChecked(!checked);
    handleFocus('');
  };

  const onChangeApart = (e) => {
    //Email 콜백
    const apart = e.target.value;
    setApart(apart);
  }

  const onChangeName = (e) => {
    //Email 콜백
    const name = e.target.value;
    setName(name);
  }

  const onChangePhone = (e) => {
    //Email 콜백
    const phone = e.target.value;
    setPhone(phone);
  }

  const onChangeEmail = (e) => {
    //Email 콜백
    const email = e.target.value;
    setEmail(email);
  }
  const onChangeRequest = (e) => {
    //Email 콜백
    const request = e.target.value;
    setRequest(request);
  }



  useEffect(() => {

    let emailCheck = false;
    if (email.includes('@') && email.includes('.')) {
      emailCheck = true;
    }

    if (emailCheck && checked) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [email, checked]);


  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }



  return (

    <ModalContainer>
      <DialogBox>
        <ModalTextBox>


          <ModalTitleBox>
            <StyledDisplay size="small">
              <SpanBlue>
                <Trans i18nKey={modalRequest.titleBold} />
              </SpanBlue>
              <span>
                <Trans i18nKey={modalRequest.title1} />
              </span>
            </StyledDisplay>

            <StyledParagraph size="medium">
              <span>
                <Trans i18nKey={modalRequest.desc1} />
              </span>
              <SpanBlueUnderline>
                <Trans i18nKey={modalRequest.descBold} />
              </SpanBlueUnderline>
              <span>
                <Trans i18nKey={modalRequest.desc2} />
              </span>
            </StyledParagraph>

          </ModalTitleBox>

          <DialogBorder />


          <InputBox>
            <InputGrid>
              <div>
                <StyledLabel size="medium">
                  <Trans i18nKey={modalEvent.apart} />
                </StyledLabel>
                <Spacing pixel="8"/>
                <RequestInputBox inputValue={focus.apart} >

                  <DialogInputText inputValue={focus.apart}>

                  </DialogInputText>
                  <DialogInput
                    value={apart}
                    placeholder={modalEvent.apartPH}
                    onInput={(e) => { handleInput(e, setApart) }}
                    onChange={onChangeApart}
                    onClick={() => handleFocus('apart')}
                    onKeyDown={(e) => handleKeyDown(e, "apart")}
                  />
                </RequestInputBox>
              </div>
              <div>
                <StyledLabel size="medium" >
                  <Trans i18nKey={modalEvent.name} />
                </StyledLabel>
                <Spacing pixel="8"/>
                <RequestInputBox inputValue={focus.name} >

                  <DialogInputText inputValue={focus.name}>

                  </DialogInputText>
                  <DialogInput
                    value={name}
                    placeholder={modalEvent.namePH}
                    onInput={(e) => { handleInput(e, setName) }}
                    onChange={onChangeName}
                    onClick={() => handleFocus('name')}
                    onKeyDown={(e) => handleKeyDown(e, "name")}
                  />

                </RequestInputBox>
              </div>
              <div>
                <StyledLabel size="medium" >
                  <Trans i18nKey={modalEvent.phone} />
                </StyledLabel>
                <Spacing pixel="8"/>
                <RequestInputBox inputValue={focus.phone} >

                  <DialogInputText inputValue={focus.phone}>

                  </DialogInputText>
                  <DialogInput
                    value={phone}
                    placeholder={modalEvent.phonePH}
                    onInput={(e) => { handleInput(e, setPhone) }}
                    onChange={onChangePhone}
                    onClick={() => handleFocus('phone')}
                    onKeyDown={(e) => handleKeyDown(e, "phone")}
                  />
                </RequestInputBox>
              </div>
              <div>
                <StyledLabel size="medium" >
                  <Trans i18nKey={modalEvent.email} />
                </StyledLabel>
                <Spacing pixel="8"/>
                <RequestInputBox inputValue={focus.email}>

                  <DialogInputText inputValue={focus.email}>

                  </DialogInputText>
                  <DialogInput
                    value={email}
                    placeholder={modalEvent.emailPH}
                    onInput={(e) => { handleInput(e, setEmail) }}
                    onChange={onChangeEmail}
                    onClick={() => handleFocus('email')}
                    onKeyDown={(e) => handleKeyDown(e, "email")}
                  />
                </RequestInputBox>
              </div>
            </InputGrid>


            <Spacing pixel="24"/>
            <StyledLabel size="medium" >
              <Trans i18nKey={modalEvent.request} />
            </StyledLabel>
            <Spacing pixel="8"/>

            
            <DialogInputBoxRequest inputValue={focus.request} >
              <DialogInputRequest 
                value={request}
                placeholder={ `문의사항` }
                onInput={(e) => { handleInput(e, setRequest) }}
                onChange={onChangeRequest}
                onClick={() => handleFocus('request')}
                onKeyDown={(e) => handleKeyDown(e, "request")}
              />
            </DialogInputBoxRequest>

            <Spacing pixel="24"/>
          </InputBox>


          <DialogPolicyBox>
            {checked ? (
              <DialogCheckBox src={IconCheckBoxBlack} onClick={policyCheck} alt="checkbox icon"/>
            ) : (
              <DialogCheckBox src={IconCheckBoxEmpty} onClick={policyCheck} alt="checkbox icon"/>
            )}

            {location.pathname.includes('/en/') ?
              (<>
                <PolicySpanText ><Trans i18nKey={modal.modal.download.agree1} />  </PolicySpanText>
                <PolicySpanText ><Trans i18nKey={modal.modal.download.agree3} /> </PolicySpanText>
                <StyledDownloadPolicyLink href="https://home.orbro.io/sign-up-conditions/:2" target="_blank" >
                  <PolicySpanTextBlue><Trans i18nKey={modal.modal.download.agree2} /></PolicySpanTextBlue>
                  <StyledUnderline />
                </StyledDownloadPolicyLink>

              </>)
              :
              (<>
                <PolicySpanText ><Trans i18nKey={modal.modal.download.agree1} />  </PolicySpanText>
                <StyledDownloadPolicyLink href="https://home.orbro.io/sign-up-conditions/:2" target="_blank" >
                  <PolicySpanTextBlue><Trans i18nKey={modal.modal.download.agree2} /></PolicySpanTextBlue>
                  <StyledUnderline />
                </StyledDownloadPolicyLink>
                <PolicySpanText ><Trans i18nKey={modal.modal.download.agree3} /> </PolicySpanText>

              </>)
            }

          </DialogPolicyBox>

          <ModalRequestBtn onClick={() => onSubmit()}>
            <LabelFFF size="large">
              <Trans i18nKey={modalRequest.btn}/>
            </LabelFFF>

          </ModalRequestBtn>

        </ModalTextBox>

      </DialogBox>


      <Backdrop
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />

    </ModalContainer>


  );
}

export default ModalRequest;