import styled from "@emotion/styled";



export const NavBarLayout = styled.div`
    height : 80px;
    background-color : #000;
    width: 100%;
    display : flex;
    object-fit : contain;

    & * {
        font-family : Pretendard ! important;
    }
`;

export const NavImg =  styled.div`
    display : flex;
    flex-direction : column;
    align-itmes : center;
    justify-content : center;
    height : 100%;
    width : 100%;
    text-align : center;
    object-fit : contain;
    cursor:pointer;

`;

export const NavImgBox = styled.div`
 width : 100%;
 align-items : center;
`;

export const NavBanner = styled.img`
    height : 24px;
    width : 240px;
`;