import React from "react";
import { TextCenterColumn } from "../Common.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import { Trans } from "react-i18next";
import common from "@src/lang/ko/event/common";
import { orbroHomeLogoWhite } from "@assets/images/apartment/logo";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import { CardIcon, DiscountCard, DiscountPage, DiscountSection } from "./Discount.style";
import { CommonState } from "@redux/reducers/commonReducer";
import { useSelector } from "react-redux";
import { ParagraphColor, Spacing, Span818181, SpanBlue, SpanFFF } from "@pages/Apartment/Apartment.style";
import { DisplayH2 } from "../Banner/Banner.style";


interface apart {
  apartName: string;
}

const Discount: React.FC<apart> = ({ apartName }) => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  
  return (
    <DiscountPage>
      <StyledGridContainer>
        <TextCenterColumn>
          <StyledDisplay size="small">
            <span>
              <Trans i18nKey={common.firework} />
              <Trans i18nKey={common.event.title} />
              <Trans i18nKey={common.firework} />
            </span>
          </StyledDisplay>
          <DisplayH2 size="small">
            <span>
              <Trans i18nKey={common.event.card.price} />
            </span>
            <SpanBlue>
              <Trans i18nKey={common.event.discount} />
            </SpanBlue>
            <span>
              <Trans i18nKey={common.event.tail} />
            </span>
          </DisplayH2>
        </TextCenterColumn>

        <DiscountSection>
          <DiscountCard img={common.event.img[layoutType]}>
            <StyledHeading size="small" >
              <Span818181>
                <Trans i18nKey={`${apartName}.name`} />
                <Trans i18nKey={' ' + common.event.card.tag} />
              </Span818181>
            </StyledHeading>
            <Spacing pixel="8"/>
            <StyledDisplay size="medium">
              <SpanFFF>
                <Trans i18nKey={common.event.card.price} />
              </SpanFFF>
              <SpanBlue>
                <Trans i18nKey={common.event.card.percent} />
              </SpanBlue>
              <SpanFFF>
                <Trans i18nKey={common.event.card.discount} />
              </SpanFFF>
            </StyledDisplay>
            <Spacing pixel="8"/>
            <ParagraphColor size="xlarge" color={"#FFF"}>
              <Trans i18nKey={common.event.card.desc} />
            </ParagraphColor>
            <CardIcon>
              <img src={orbroHomeLogoWhite} alt="ORBRO logo"/>
            </CardIcon>
          </DiscountCard>
        </DiscountSection>

      </StyledGridContainer>
    </DiscountPage>
  )
}

export default Discount;