import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { CommonTextProps } from "@components/Text/CommonText.style";
import { TypeMap } from "@core/util";
import styled from "@emotion/styled";


type SizeTypes = 'large' | 'medium' | 'small';
interface StyledDisplayProps extends CommonTextProps {
  size: SizeTypes;
}

type DisplayMapData = {
  size: string,
  height: string,
}



const desktopSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '68px', height: '1.21' },
  medium: { size: '52px', height: '1.23' },
  small: { size: '36px', height: '1.22' },
};

const tabletSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '52px', height: '1.23' },
  medium: { size: '44px', height: '1.18' },
  small: { size: '32px', height: '1.25' },
};

const mobileSizeMap: TypeMap<SizeTypes, DisplayMapData> = {
  large: { size: '36px', height: '1.22' },
  medium: { size: '32px', height: '1.25' },
  small: { size: '28px', height: '1.29' },
};




export const BannerButton = styled.a`

  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  width : fit-content;
  margin : auto;
  margin-top : 32px;
  background: var(--primitive-blue-600, #2A70F0);
`
;


export const BannerImg = styled.div`
poistion : relative;
  display : flex;
  object-fit : contain;
  
  width : 100%;

  aspect-ratio : auto 1920 / 680 ;
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    aspect-ratio : auto 960 / 540 ;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    aspect-ratio : auto 393 / 400 ;
  }

  & img {
    height : 100%;
    width : 100%;
    aspect-ratio : auto 1920 / 680 ;
  }

`;



export const DisplayH1 = styled.h1<StyledDisplayProps>`

  font-size: ${({ size }) => desktopSizeMap[size].size};
  line-height: ${({ size }) => desktopSizeMap[size].height};
  font-style : bold;
  font-weight : 700;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }

`;




export const DisplayH2 = styled.h2<StyledDisplayProps>`

  font-size: ${({ size }) => desktopSizeMap[size].size};
  line-height: ${({ size }) => desktopSizeMap[size].height};
  font-style : bold;
  font-weight : 700;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    font-size: ${({ size }) => tabletSizeMap[size].size}; 
    line-height: ${({ size }) => tabletSizeMap[size].height};
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    font-size: ${({ size }) => mobileSizeMap[size].size};
    line-height: ${({ size }) => mobileSizeMap[size].height};
  }

`;