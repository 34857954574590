

export default { 
    titleBold : '견적 및 도입문의 ',
    title1 : '가 필요하신가요?',
    desc1 : '도움이 필요하신가요? ',
    descBold : '1522-9928',
    desc2: ' 로 연락 주시면<br/>출입 전문가가 도와 드리도록 하겠습니다.',

    
    elevatorLink : '엘레베이터 연동',
    on : '연동',
    off : '연동 안함',

    btn: '서비스 도입 문의'



}