import { StyledGridContainer } from "@components/Grid/Grid.style"
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text"
import React from "react"
import { Trans } from "react-i18next"
import { SecureImgL, SecureImgR, SecureImgSection, TextBox } from "./Secure.style"
import main from "@src/lang/ko/apartment/main"
import { useSelector } from "react-redux"
import { CommonState } from "@redux/reducers/commonReducer"
import { BorderBottomLine, HeadingColor, Layout128to96, Spacing, Span818181, SpanBlue, SpanFF6C61 } from "../Apartment.style"
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style"




const Secure = () => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <Layout128to96>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.secure.title1}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <span>
            <Trans i18nKey={`${main.secure.title2}`} />
          </span>
          <SpanBlue>
            <Trans i18nKey={`${main.secure.titleBold}`} />
          </SpanBlue>
        </DisplayH2>
        <Spacing pixel="16" />
        <StyledParagraph size="medium">
          <Trans i18nKey={`${main.secure.desc}`} />
        </StyledParagraph>

        <SecureImgSection>
          <SecureImgL img={main.secure.box.left.img[layoutType]}>
            <TextBox>
              <HeadingColor size="xxxxsmall" color='#646464'>
                <Trans i18nKey={`${main.secure.box.left.tag}`} />
              </HeadingColor>
              <Spacing pixel="8" />
              <StyledHeading size="small">
                <SpanFF6C61>
                  <Trans i18nKey={`${main.secure.box.left.titleBold1}`} />
                </SpanFF6C61>
                <Span818181>
                  <Trans i18nKey={`${main.secure.box.left.title1}`} />
                </Span818181>
              </StyledHeading>
              <Spacing pixel="8" />
              <StyledHeading size="small">
                <Span818181>
                  <Trans i18nKey={`${main.secure.box.left.title2}`} />
                </Span818181>
                <SpanFF6C61>
                  <Trans i18nKey={`${main.secure.box.left.titleBold2}`} />
                </SpanFF6C61>
              </StyledHeading>
            </TextBox>
          </SecureImgL>
          <SecureImgR img={main.secure.box.right.img[layoutType]}>
            <TextBox>
              <HeadingColor size="xxxxsmall" color='#2A70F0'>
                <Trans i18nKey={`${main.secure.box.right.tag}`} />
              </HeadingColor>
              <Spacing pixel="8" />
              <StyledHeading size="small">
                <span>
                  <Trans i18nKey={`${main.secure.box.right.title1}`} />
                </span>
                <SpanBlue>
                  <Trans i18nKey={`${main.secure.box.right.titleBold1}`} />
                </SpanBlue>
              </StyledHeading>
              <Spacing pixel="8" />
              <StyledHeading size="small">
                <span>
                  <Trans i18nKey={`${main.secure.box.right.title2}`} />
                </span>
                <SpanBlue>
                  <Trans i18nKey={`${main.secure.box.right.titleBold2}`} />
                </SpanBlue>
              </StyledHeading>
            </TextBox>
          </SecureImgR>
        </SecureImgSection>


        <Spacing pixel="96" />
        <BorderBottomLine/>

      </StyledGridContainer>

    </Layout128to96>

  )
}


export default Secure;