
import React, { useState } from 'react';

import { NavBarLayout, NavImg } from '@layouts/Default/components/NavBox/NavBoxEvent.style';
import { StyledLabel } from '@components/Text';
import { Trans } from 'react-i18next';
import { Spacing, Span00C37D, SpanBlue, SpanFFF } from '@pages/Apartment/Apartment.style';
import { EventNavImg, NavContainer } from './Nav.style';
import common from '@src/lang/ko/event/common';
import ModalRequest from '@pages/Apartment/Banner/ModalReuqest/ModalRequest';

import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";




const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,


  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle
}



interface apart {
  apartName: string;
}

const NavApart: React.FC<apart> = ({ apartName }) => {

  const path = list[apartName];
  const [isModal, setModal] = useState<boolean>(false);
  const handleModal = () => {
    setModal(!isModal);
  }

  return (
    <>
      <NavBarLayout>
        <NavImg onClick={() => handleModal()}>
          <NavContainer>
            <EventNavImg src={path.nav} alt='ORBRO Product Event Banner' />
          </NavContainer>
          <Spacing pixel='8'/>
          <StyledLabel size='small'>
            <span >
              <Trans i18nKey={common.firework + ' '} />
            </span>
            <SpanFFF>
              <Trans i18nKey={`${apartName}.name`} />
            </SpanFFF>
            <SpanFFF>
              <Trans i18nKey={` 분양 이벤트, `} />
            </SpanFFF>
            <SpanBlue>
              <Trans i18nKey={`원패스 `} />
            </SpanBlue>
            <Span00C37D>
              <Trans i18nKey={`할인 판매중 `} />
            </Span00C37D>
            <span >
              <Trans i18nKey={common.firework} />
            </span>
          </StyledLabel>
        </NavImg>
      </NavBarLayout>

      {isModal &&
        <>
          <ModalRequest onClickToggleModal={handleModal} />
        </>
      }
    </>
  );
};

export default NavApart;
