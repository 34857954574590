import React, { useEffect, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { DefaultLayout } from '@layouts/index';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {

  Apartment, BusanDetre, 
  CheongjuParklane, 
  DaunFirstheim, 
  DomaForena, DongraeLotte, GagyeongIpark, GayangHillstate, GeomdanJeil, JemulpoPark, MaegyoPellucid, 
  MapoUrbanpiece, MunjeongHillstate, OsansegyoParagon, 
  Reventus, 
  RoutePage, SeosanIpark, TangjeonInfinity, ThePole, UamHarrington,
   UiwangDesian, UnamFirstiche, UnjeongLynn, 
  WanggilRoyalpark, WeparkIlgok, WonbongHildesheim, ZenithCentral,

} from './index';

import '@css/app.styl';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutType, setGridLayoutType } from '@redux/action-creators/common';

import { SCREEN_BREAKPOINT } from '@components/Grid/Grid.style';
import { Global, ThemeProvider } from '@emotion/react';
import global from '@assets/styled/global';

import { darkTheme, lightTheme } from '@assets/styled/color.style';
import { CommonState } from '@redux/reducers/commonReducer';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-M72TS2VD'
}

const GOOGLE_ANALYTICS_ID = 'G-HTY7NKHMKH';

const helmetContext = {} as any;
interface props {
  state: any;
  queryClient?: any;
}


const App = () => {
  // React
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux
  const darkMode = useSelector((state: CommonState) => state.darkMode);

  // Hooks
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send('pageview');
    }

    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID, { gtagOptions: { debug_mode: true } });
      TagManager.initialize(tagManagerArgs);
    }

    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth >= 1221) {
        dispatch(setLayoutType('desktop'));
      } else if (innerWidth > 721) {
        dispatch(setLayoutType('tablet'));
      } else {
        dispatch(setLayoutType('mobile'));
      }

      if (innerWidth > SCREEN_BREAKPOINT.TABLET) {
        dispatch(setGridLayoutType('desktop'));
      } else if (innerWidth > SCREEN_BREAKPOINT.MOBILE) {
        dispatch(setGridLayoutType('tablet'));
      } else {
        dispatch(setGridLayoutType('mobile'));
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Theme
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Global styles={global} />
          <Routes>

            <Route path="/" element={<DefaultLayout />}>

            <Route path="/" element={<Apartment/>} />

            <Route path="/route" element={<RoutePage/>}/>

            <Route path="/apart/munjeong-hillstate" element={<MunjeongHillstate/>} />
            <Route path="/apart/uam-harrington" element={<UamHarrington/>} />
            <Route path="/apart/doma-forena" element={<DomaForena/>} />
            <Route path="/apart/thepole" element={<ThePole/>} />
            <Route path="/apart/unjeong-lynn" element={<UnjeongLynn/>} />
            <Route path="/apart/uiwang-desian" element={<UiwangDesian/>} />            
            <Route path="/apart/seosan-ipark" element={<SeosanIpark/>} />
            <Route path="/apart/osansegyo-paragon" element={<OsansegyoParagon/>} />
            <Route path="/apart/wanggil-royalpark" element={<WanggilRoyalpark/>} />
            <Route path="/apart/busan-detre" element={<BusanDetre/>} />
            <Route path="/apart/wonbong-hildesheim" element={<WonbongHildesheim/>} />
            <Route path="/apart/unam-firstiche" element={<UnamFirstiche/>} />
            <Route path="/apart/mapo-urbanpiece" element={<MapoUrbanpiece/>} />
            

            <Route path="/apart/jemulpo-parkmaison" element={<JemulpoPark/>} />
            <Route path="/apart/gagyeong-ipark" element={<GagyeongIpark/>} />
            <Route path="/apart/raemian-reventus" element={<Reventus/>} />
            <Route path="/apart/geomdan-jeil" element={<GeomdanJeil/>} />
            <Route path="/apart/maegyo-pellucid" element={<MaegyoPellucid/>} />
            <Route path="/apart/thezenith-central" element={<ZenithCentral/>} />
            <Route path="/apart/thesharp-infinitycity" element={<TangjeonInfinity/>} />
            <Route path="/apart/cheongju-parklane" element={<CheongjuParklane/>} />
            <Route path="/apart/hillstate-gayang" element={<GayangHillstate/>} />
            <Route path="/apart/wepark-ilgok" element={<WeparkIlgok/>} />
            <Route path="/apart/daun-thefirstheim" element={<DaunFirstheim/>} />
            <Route path="/apart/dongrae-lottecastle" element={<DongraeLotte/>} />


             
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
};

export default App;
