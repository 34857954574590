import styled from "@emotion/styled";


export const Font = styled.div`


& * {
  font-family : Pretendard ! important;
}

`;

export const TextCenterColumn = styled.div`
    width : 100%;
    text-align : center;
    display : flex;
    flex-direction : column;

`;


