import { BannerTangjeongInfinityD, BannerTangjeongInfinityM, BannerTangjeongInfinityT, LogoTangjeongInfinity, NavTangjeongInfinity } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerTangjeongInfinityD,
    tablet : BannerTangjeongInfinityT,
    mobile : BannerTangjeongInfinityM,
  },
  logo : LogoTangjeongInfinity,
  nav : NavTangjeongInfinity,
  name: '더샵 탕정인피니티시티',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',
  
  a : 36.7883094,
  b : 127.0767714,

  info : [
    {
      data : '충청남도 아산시 탕정면 매곡리 857'
    },
    {
      data : '총 1,140 세대',
    },
    {
      data : '49㎡, 59㎡A, 59㎡B, 74㎡B', 
    },
    {
      data : '(주)포스코이앤씨'
    },
    {
      data : '탕정도시개발주식회사',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '탕정역세권',
      desc: '1호선 탕정역 도보 10분, 천안아산역 KTX, SRT, 아산, 천안고속도로 인접'
    },
    {
      icon: Icon3Dsquare,
      title: '중심생활권',
      desc: '백화점, 대형마트 등 다양한 생활편의시설과 용곡공원, 지산공원, 곡교천'
    },
    {
      icon: Icon3Dlife,
      title: '직주근접 생활환경',
      desc: '약 4,300세대 규모의 아산탕정지구 도시개발구역 예정'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 학군',
      desc: '유치원, 초등학교, 중학교가 단지 앞 한걸음.'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/gr599/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91668?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },

    ]

  },

  map: '충청남도 아산시 탕정면 매곡리 857'


}