import { BannerDomaForenaD, BannerDomaForenaM, BannerDomaForenaT, BannerUamHarringtonD, BannerUamHarringtonM, BannerUamHarringtonT, LogoDomaForena, LogoUamHarrington, NavDomaForena, NavUamHarrington } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerDomaForenaD,
    tablet : BannerDomaForenaT,
    mobile : BannerDomaForenaM
  },
  logo : LogoDomaForena,
  nav : NavDomaForena,
  name: '도마 포레나해모로',
  likes : 1391,
  user: '468,511',
  count: '15,445,432',

  a : 36.311551,
  b : 127.382274,

  info : [
    {
      data : '대전광역시 서구 도마동 181-1번지 일원'
    },
    {
      data : '총 818 세대',
    },
    {
      data : '59㎡A, 59㎡B, 74㎡A, 74㎡B, 84㎡, 101㎡', 
    },
    {
      data : '㈜한화/건설, ㈜에이치제이중공업 건설부문'
    },
    {
      data : '도마·변동9재정비촉진구역<br/>주택재개발정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '쾌속 교통 프리미엄',
      desc: '도시철도 2호선&충청권 광역철도, 도마역(가칭) 예정, 계백로, 도산로, 안영IC, 대전 서남부터미널 이용 편리'
    },
    {
      icon: Icon3Dsquare,
      title: '자연 프리미엄',
      desc: '오량산과 복수근린공원 및 유등천 수변산책로&자전거길 등 쾌적한 단지 주변 자연환경'
    },
    {
      icon: Icon3Dlife,
      title: '특급 생활 프리미엄',
      desc: '단지 인근 도마네거리 중심상업시설, 도마큰시장 및 코스트코, 홈플러스, CGV'
    },
    {
      icon: Icon3Dsecure,
      title: '안심 교육환경',
      desc: '반경 1km 내 위치한 복수초, 삼육초, 삼육중, 버드내중, 제일고 등 안심 교육환경'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fbpac/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '4억 6,100',
            competition: '-',
          },
          {
            size: '74A',
            price: '5억 7,400',
            competition: '-',
          },
          {
            size: '84타입',
            price: '6억 4,500',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/86931',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84A/59A',
            price: '4억 6,100',
            competition: '발표 예정',
          },
          {
            size: '102A/74A',
            price: '5억 7,400',
            competition: '발표 예정',
          },
          {
            size: '110/84',
            price: '6억 4,500',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6027261&supp_cd=9040228&DetailType=Complex&SYMap=35.128753,129.070612,16&a=&b=C12',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '4억 6,100',
            competition: '-',
          },
          {
            size: '74A',
            price: '5억 7,400',
            competition: '-',
          },
          {
            size: '84타입',
            price: '6억 4,500',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '대전광역시 서구 도마동 181-1번지 일원'


}