
import { BannerMapoUrbanPieceD, BannerMapoUrbanPieceM, BannerMapoUrbanPieceT, BannerOsansegyoParagonD, BannerOsansegyoParagonM, BannerOsansegyoParagonT, BannerUnjeongLynnD, BannerUnjeongLynnM, BannerUnjeongLynnT, LogoMapoUrbanPiece, LogoOsansegyo, LogoUnjeongLynn, NavMapoUrbanPiece, NavOsansegyo, NavUnjeongLynn } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerMapoUrbanPieceD,
    tablet : BannerMapoUrbanPieceT,
    mobile : BannerMapoUrbanPieceM
  },
  logo : LogoMapoUrbanPiece,
  nav : NavMapoUrbanPiece,
  name: '마포 어반피스',
  likes : 1389,
  user: '468,511',
  count: '15,445,432',
  
   
  a : 37.5526938,
  b : 126.9557684,

  info : [
    {
      data : '서울특별시 마포구 아현동 613-10'
    },
    {
      data : '총 239 세대',
    },
    {
      data : '49㎡, 59㎡, 74㎡, 84㎡A, 84㎡B', 
    },
    {
      data : '(주)대우건설'
    },
    {
      data : '마포구3구역3지구도시환경정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '입지 환경',
      desc: '명품주거벨트 아현뉴타운의 가치를 품은 핵심가치, 서울 3대 업무지구와 빠르게 잇는 직주근접 가치'
    },
    {
      icon: Icon3Dsquare,
      title: '초고속 멀티 교통망의 중심',
      desc: '5호선 애오개역과 2호선 아현역 더블역세권, 공덕역이 가까운 특급교통.'
    },
    {
      icon: Icon3Dlife,
      title: '수준이 다른 원스톱 생활환경',
      desc: '아현초, 아현중, 숭문중, 숭문고, 연세대, 이화여대 인접. 이마트, 롯데마트, 현대백화점 등 생활편의시설'
    },
    {
      icon: Icon3Dsecure,
      title: '푸르지오 브랜드 프리미엄',
      desc: '마포 푸르지오 브랜드타운의 자부심을 이어갈 푸르지오 브랜드 가치'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/f9F09/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '74㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/88496',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '59㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '74㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '74㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '서울특별시 마포구 아현동 613-10'


}