import seo from "@src/lang/ko/seo";

import imunipark from "@src/lang/ko/event/list/imunipark";
import munjeonghillstate from '@src/lang/ko/event/list/munjeonghillstate';
import uamharrington from '@src/lang/ko/event/list/uamharrington';
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";


const ko = {
    seo,
    imunipark,
    
    //1차 배포
    munjeonghillstate, 
    uamharrington,
    domaforena,
    thepole,
    unjeonglynn,
    uiwangdesian,
    seosanipark,
    osansegyoparagon,
    wanggilroyalpark,
    busandetre,
    wonbonghildesheim,
    unamfirstiche,
    mapourbanpiece,

    //2차 배포
    jemulpoparkmaison,
    gagyeongipark,
    raemianreventus,
    geomdanjeil,
    maegyopellucid,
    zenithcentral,
    tangjeonginfinity,
    cheongjuparklane,
    gayanghillstate,
    weparkilgok,
    daunfirstheim,
    dongraelottecastle


};

export default ko;

