import { BannerMaegyoPellucidD, BannerMaegyoPellucidM, BannerMaegyoPellucidT, BannerUamHarringtonD, BannerUamHarringtonM, BannerUamHarringtonT, LogoMaegyoPellucid, LogoUamHarrington, NavMaegyoPellucid, NavUamHarrington } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerMaegyoPellucidD,
    tablet : BannerMaegyoPellucidT,
    mobile : BannerMaegyoPellucidM
  },
  logo : LogoMaegyoPellucid,
  nav : NavMaegyoPellucid,
  name: '매교역 팰루시드',
  likes : 961,
  user: '468,511',
  count: '15,445,432',


  a : 37.2636597,
  b : 127.0174838,

  info : [
    {
      data : '경기도 수원시 권선구 세류동 817-72'
    },
    {
      data : '총 2,178 세대',
    },
    {
      data : '48㎡, 59㎡, 71㎡, 84㎡, 101㎡', 
    },
    {
      data : '삼성물산(주), SK ecoplant(주), KOLON GLOBAL(주)'
    },
    {
      data : '권선 113-6구역주택재개발정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '교통 입지',
      desc: '수인분당선 매교역 1,2번 출구 바로 앞 역세권, 1호선, KTX, GTX-C(예정) 수원역 접근 편리'
    },
    {
      icon: Icon3Dsquare,
      title: '비전',
      desc: '매교역 재개발 사업으로 형성되는 약 12,000세대 신흥주거타운'
    },
    {
      icon: Icon3Dlife,
      title: '라이프',
      desc: '세계문화유산 수원화성, 수원천, 팔달공원 등 다양한 주변환경과 대형 쇼핑몰(수원역), 대학병원(가톨릭대, 아주대) 등 편리한 생활인프라'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 교육환경',
      desc: '권선초, 수원중, 수원고 등 인접한 교육환경'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/b5r0d/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '71A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/51748',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '71A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '71A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '경기도 수원시 권선구 세류동 817-72'


}