import { BannerUiwangDesianD, BannerUiwangDesianM, BannerUiwangDesianT, LogoUiwangDesian, NavUiwangDesian } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerUiwangDesianD,
    tablet : BannerUiwangDesianT,
    mobile : BannerUiwangDesianM
  },
  logo : LogoUiwangDesian,
  nav : NavUiwangDesian,
  name: '의왕 센트라인 데시앙',
  likes : 1989,
  user: '468,511',
  count: '15,445,432',

  a : 37.360584,
  b : 126.965616,

  info : [
    {
      data : '경기도 의왕시 오전동 32-4'
    },
    {
      data : '총 532 세대',
    },
    {
      data : '37㎡A, 37㎡B, 59㎡A, 59㎡B, 84㎡A, 84㎡B, 98㎡', 
    },
    {
      data : '(주)태영건설'
    },
    {
      data : '오전나구역주택재개발정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '단지 내, 시설',
      desc: '경로당, 독서실, 샤워실, 작은도서관, 피트니스, 골프 연습장 등'
    },
    {
      icon: Icon3Dsquare,
      title: '건강과 안전',
      desc: '스마트 환기 시스템, 청전 환기 전열교환기, 음성인식 월패드, 자동수전'
    },
    {
      icon: Icon3Dlife,
      title: '편리하고 경제적인 생활',
      desc: '전세대 LED조명, 누수감지 센서, 번호인식 주차관제, 전기차 충전설비'
    },
    {
      icon: Icon3Dsecure,
      title: '첨단기술 적용',
      desc: '방문차량 사전 예약, 승강기 향균 시스템, 안면인식 로비폰'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dJz8a/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '6억 7,500',
            competition: '3.5 : 1',
          },
          {
            size: '59B',
            price: '6억 8,500',
            competition: '1.91 : 1',
          },
          {
            size: '84A',
            price: '9억 3,700',
            competition: '1.32 : 1',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/79733',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '82A/59A',
            price: '6억 7,500',
            competition: '11.4 : 1',
          },
          {
            size: '114B/84A',
            price: '9억 3,700',
            competition: '1.3 : 1',
          },
          {
            size: '131/98',
            price: '10억 6,900',
            competition: '5.6 : 1',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6024346&supp_cd=9031368&DetailType=Complex&SYMap=37.360584,126.965616,16&a=',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '6억 7,500',
            competition: '3.5 : 1',
          },
          {
            size: '59B',
            price: '6억 8,500',
            competition: '1.91 : 1',
          },
          {
            size: '84A',
            price: '9억 3,700',
            competition: '1.32 : 1',
          },
        ]
      },

    ]

  },

  map: '경기도 의왕시 오전동 32-4'


}