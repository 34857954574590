import styled from "@emotion/styled";


export const PriceBtnBox = styled.div`
  display : flex;
  flex-direction : row;
  gap : 8px;
  margin-top : 48px;
`;

export const FuncBtn = styled.div<{color : string}>`

  padding : 12px;
  display : flex;
  justify-content: center;
  align-items: center;
  border-radius : 8px;
  cursor : pointer;
  text-align : center;

  ${({color}) => color && `background-color : ${color};`}
`;


export const PriceBoxSection = styled.div`
  margin-top : 48px;
  display : flex;
  width: 100%;
  
  object-fit : contain;
`;
