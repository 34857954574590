import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { DisplayLeftAuto, PriceBox, PriceBoxSection, PriceBtn, PriceBtnBox } from "./Price.style";
import main from "@src/lang/ko/apartment/main";
import { BorderBottomLine, HeadingColor, LabelColor, Layout128to96, Spacing, Span818181, SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const Price = () => {


  const [option, setOption] = useState<number>(0);

  const handleOption = (i: number) => {
    setOption(i);
  }

  return (
    <Layout128to96>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.price.title}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.price.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.price.title2}`} />
          </span>
        </DisplayH2>

        <PriceBtnBox>
          {main.price.btn.map((data, i) => (
            <>
              {option === i ?
                <PriceBtn color={'#2A70F0'}
                  onClick={() => handleOption(i)}>
                  <LabelColor size="small" color={'#fff'}>
                    <Trans i18nKey={main.price.btn[i].title} />
                  </LabelColor>
                </PriceBtn>
                :
                <PriceBtn color={'#EAF1FD'}
                  onClick={() => handleOption(i)}>
                  <LabelColor size="small" color={'#2A70F0'}>
                    <Trans i18nKey={main.price.btn[i].title} />
                  </LabelColor>
                </PriceBtn>
              }
            </>
          ))}
        </PriceBtnBox>

        {option === 0 ? <>
          <Spacing pixel="32"/>
          <StyledHeading size="xsmall">
            <Span818181>
              <Trans i18nKey={`${main.price.desc1}`} />
            </Span818181>
            <span>
              <Trans i18nKey={`${main.price.descBold1}`} />
            </span>
            <Span818181>
              <Trans i18nKey={`${main.price.desc2}`} />
            </Span818181>
          </StyledHeading>

        </>
          :
          <>
            <Spacing pixel="32"/>
            <StyledHeading size="xsmall">
              <Span818181>
                <Trans i18nKey={`${main.price.desc3}`} />
              </Span818181>
              <span>
                <Trans i18nKey={`${main.price.descBold2}`} />
              </span>
              <Span818181>
                <Trans i18nKey={`${main.price.desc4}`} />
              </Span818181>
            </StyledHeading>
          </>}

        <PriceBoxSection>
          {Array(3).fill(null).map((_, i) => (
            <>
              <PriceBox>
                <div>
                  <HeadingColor size="xsmall" color='#818181'>
                    <Trans i18nKey={`${main.price.box[option][i].title1}`} />
                  </HeadingColor>
                  <StyledHeading size="xsmall">
                    <Span818181>
                      <Trans i18nKey={`${main.price.box[option][i].title2}`} />
                    </Span818181>
                    <span>
                      <Trans i18nKey={`${main.price.box[option][i].titleBold}`} />
                    </span>
                  </StyledHeading>
                </div>
                <DisplayLeftAuto size="small">
                  <Trans i18nKey={`${main.price.box[option][i].price}`} />
                </DisplayLeftAuto>
              </PriceBox>
            </>
          ))}

        </PriceBoxSection>
        
        <Spacing pixel="96"/>
        <BorderBottomLine/>
        
      </StyledGridContainer>
    </Layout128to96>

  )
}


export default Price;