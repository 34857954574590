import styled from "@emotion/styled";


export const NavContainer = styled.div`
    width : 100%;
    align-items : center;
`;

export const EventNavImg = styled.img`
    height : 22px;
    width : auto;
    max-width : 1920px;
    aspect-ratio : auto;
`;