


export default {

    list : [
        {
            name : '문정 힐스테이트',
            link : 'munjeong-hillstate'
        },
        {
            name : '우암 해링턴',
            link : 'uam-harrington'
        },
        {
            name : '도마 포레나',
            link : 'doma-forena'
        },
        {
            name : '더폴',
            link : 'thepole'
        },
        {
            name : '운정 우미린',
            link : 'unjeong-lynn'
        },
        {
            name : '의왕 데시앙',
            link : 'uiwang-desian'
        },
        {
            name : '서산 아이파크',
            link : 'seosan-ipark'
        },
        {
            name : '오산세교 파라곤',
            link : 'osansegyo-paragon'
        },
        {
            name : '왕길 로얄파크',
            link : 'wanggil-royalpark'
        },
        {
            name : '부산 디에르트그랑루체',
            link : 'busan-detre'
        },
        {
            name : '원봉 힐데스하임',
            link : 'wonbong-hildesheim'
        },
        {
            name : '운암 퍼스티체',
            link : 'unam-firstiche'
        },
        {
            name : '마포 어반피스',
            link : 'mapo-urbanpiece'
        },
        {
            name : ' ',
            link : ' '
        },
        {
            name : 'e편한세상 제물포역 파크메종',
            link : 'jemulpo-parkmaison'
        },
        {
            name : '가경 아이파크',
            link : 'gagyeong-ipark'
        },
        {
            name : '래미안 레벤투스',
            link : 'raemian-reventus'
        },
        {
            name : '제일 풍경채검단4',
            link : 'geomdan-jeil'
        },
        {
            name : '매교역 팰루시드',
            link : 'maegyo-pellucid'
        },
        {
            name : '두산위브더제니스 센트럴 계양',
            link : 'thezenith-central'
        },
        {
            name : '더샵 탕정 인피니티시티',
            link : 'thesharp-infinitycity'
        },
        {
            name : '청주 동일하이빌 파크레인',
            link : 'cheongju-parklane'
        },
        {
            name : '힐스테이트 가양 더 와이즈',
            link : 'hillstate-gayang'
        },
        {
            name : '위파크 일곡공원',
            link : 'wepark-ilgok'
        },
        {
            name : '다운한양립스 더퍼스트하임',
            link : 'daun-thefirstheim'
        },
        {
            name : '동래 롯데캐슬 시그니처',
            link : 'dongrae-lottecastle'
        },
    ]

}