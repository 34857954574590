import React from "react";
import { Font } from "../Common/Common.style";
import NavApart from "../Common/Nav/Nav";
import NavBox from '@src/layouts/Default/components/NavBox/NavBox';
import Exam from "@pages/Apartment/Exam/Exam";
import Customer from "@pages/Apartment/Customer/Customer";
import Banner from "../Common/Banner/Banner";
import Discount from "../Common/Discount/Discount";
import Request from "../Common/Request/Request";
import Info from "../Common/Info/Info";
import Point from "../Common/Point/Point";
import Price from "../Common/Price/Price";
import Map from "../Common/Map/Map";

import SEO from "@pages/SEO";
import seo from "@src/lang/ko/seo";

const WanggilRoyalpark = () => {


  const apartName = 'wanggilroyalpark';
  const helmetTitle = seo.apart[apartName].title;
  const ogKey = seo.apart[apartName].key
  const ogDesc = seo.apart[apartName].desc
  const ogImg = seo.apart[apartName].img
  const ogUrl = seo.apart[apartName].url

  return (
    <>
      <SEO 
        lang={"ko"}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
      />
      <Font>
        <NavApart apartName={apartName} />
        <NavBox/>
        <Banner apartName={apartName} index = {2} />
        <Discount apartName={apartName} />
        <Request apartName={apartName} />
        <Info apartName={apartName} />
        <Point apartName={apartName} />
        <Price apartName={apartName} />
        <Map apartName={apartName} />

        <Exam />
        <Customer />

      </Font>
    </>
  )
}


export default WanggilRoyalpark;