import { BannerRaemianReventusD, BannerRaemianReventusM, BannerRaemianReventusT, LogoRaemianReventus, NavRaemianReventus } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerRaemianReventusD,
    tablet : BannerRaemianReventusT,
    mobile : BannerRaemianReventusM,
  },
  logo : LogoRaemianReventus,
  nav : NavRaemianReventus,
  name: '래미안 레벤투스',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',
  
  a : 37.4935694,
  b : 127.0460174,

  info : [
    {
      data : '서울특별시 강남구 도곡동 540'
    },
    {
      data : '총 308 세대',
    },
    {
      data : '45㎡, 58㎡, 74㎡, 84㎡', 
    },
    {
      data : '삼성물산(주)'
    },
    {
      data : '도곡삼호아파트주택재건축정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '교통 프리미엄',
      desc: '한티역 도보 10분 이내, 역삼역과 매봉역 도보 20분 이내. 강남역 지하철 5분 거리'
    },
    {
      icon: Icon3Dsquare,
      title: '지역 프리미엄',
      desc: '삼성역 복합환승센터 개발, GTX-A 삼성역 역사 등 교통호재'
    },
    {
      icon: Icon3Dlife,
      title: '환경 프리미엄',
      desc: '이마트, 롯데백화점 등 각종 상권과 매봉산, 양재천 등의 자연환경을 누릴 수 있습니다.'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 학군',
      desc: '언주초, 도곡중, 역삼중, 숙명여중, 숙명여고, 진선여고와 15분 이내에 대치동 학원가'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/gfg98/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/90649?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
          {
            size: '-',
            price: '-',
            competition: '분양 예정',
          },
        ]
      },

    ]

  },

  map: '서울특별시 강남구 도곡동 540'


}