import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";




export const PriceBtnBox = styled.div`
  display : flex;
  flex-direction : row;
  gap : 8px;
  margin-top : 48px;
`;

export const FuncBtn = styled.div`

  padding : 12px;
  display : flex;
  justify-content: center;
  align-items: center;
`;


export const PriceBoxSection = styled.div`
  margin-top : 48px;
  display : flex;
`;


export const ImgSection = styled.div`
  display : flex;
  margin-top : 48px;
  width : 100%:
`;

export const ImgBox = styled.div<{img : string}>`
display : flex;
flex-direction :column;

background-size : cover;
background-position : center;
height : auto;
aspect-ratio : 600 / 480 ;  

${({img})=> img&& `background-image : url(${img});`}

${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  height : auto;
  aspect-ratio : 440 / 480 ;  
}
${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  height : auto;
  aspect-ratio : 361 / 441 ;  
}

`;


export const InnerTextBox = styled.div`

display : flex;
flex-direction : column;
  padding  : 32px;
  gap :8px;
  width : 100%;
`;



export const UpgradeImg = styled.div`
  display : flex;
  width : 100%;
  object-fit : contain;

`;