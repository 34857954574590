import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import React from "react";
import { Trans } from "react-i18next";
import main from "@src/lang/ko/apartment/main";
import { BoxText, BoxUp, NewTag } from "./OneService.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { LabelColor, Layout128to96, Spacing, SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const OneService = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <Layout128to96>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.oneService.title1}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.oneService.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.oneService.title2}`} />
          </span>
        </DisplayH2>

        <Spacing pixel="48"/>
        <StyledGridRow >
          {main.oneService.boxUp.map((data, i) => (
            <>
              {i < 2 ?
                <StyledGridColumn desktop={6} tablet={4} mobile={4}>
                  <BoxUp position={0} img={data.img[layoutType]}>
                    <BoxText>
                      <StyledHeading size="xxxxsmall">
                        <Trans i18nKey={`${data.tag}`} />
                      </StyledHeading>
                      <StyledHeading size="medium">
                        <Trans i18nKey={`${data.title}`} />
                      </StyledHeading>
                    </BoxText>
                  </BoxUp>
                </StyledGridColumn>
                :
                <StyledGridColumn desktop={4} tablet={layoutType ==='tablet' && i ===4 ? 8 : 4} mobile={4}>
                  <BoxUp position={layoutType === 'tablet' && i === 4 ? 2 : 1} img={data.img[layoutType]}>
                    <BoxText>
                      <StyledHeading size="xxxxsmall">
                        <Trans i18nKey={`${data.tag}`} />
                      </StyledHeading>
                      <StyledHeading size="medium">
                        <Trans i18nKey={`${data.title}`} />
                      </StyledHeading>
                      {data.new === "1" &&
                        <>
                          <NewTag>
                            <LabelColor size="small" color={"#00C37D"}>
                              <Trans i18nKey={`${main.newTag}`} />
                            </LabelColor>
                          </NewTag>
                        </>}
                    </BoxText>
                  </BoxUp>
                </StyledGridColumn>
              }
            </>
          ))}
        </StyledGridRow>
        
      </StyledGridContainer>
    </Layout128to96>

  )
}


export default OneService;