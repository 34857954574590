import { TextCenterColumnFlex } from "@pages/Apartment/Banner/Banner.style";
import React, { useEffect, useState } from "react";
import { CountBox, CountSection, Icon, IconText, RequestButton, RequestPage, ThumbDiv, ThumbIcon } from "./Request.style";
import { StyledParagraph } from "@components/Text";
import { Trans } from "react-i18next";
import common from "@src/lang/ko/event/common";
import { IconPersonLineBlue, IconThumbYellow, IconUserCheckBlue } from "@assets/images/icon";
import { StyledGridContainer } from "@components/Grid/Grid.style";

import { HeadingColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import { DisplayLeftAuto } from "@pages/Apartment/Price/Price.style";
import { DisplayH2 } from "../Banner/Banner.style";

import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";

const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}


interface apart {
  apartName: string;
}

const Request: React.FC<apart> = ({ apartName }) => {
  const path = list[apartName];
  const [like, setLike] = useState<number>(path.likes);
  const clickLikes = () => {
    setLike(like + 1);
  }

  const getNumber = () => {
    return like.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [user, setUser] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const currentDate = new Date();

    // 연, 월, 일 추출
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1); // 월은 0부터 시작하므로 1을 더하고 2자리로 패딩
    const day = currentDate.getDate();

    const month_user = (month * 89 + 598) * 89;
    const day_user = (day * 89 + 98) * 89;
    const use_user = month_user + day_user;
    setUser(use_user);


    const month_count = (month * 189 + 53498) * 189;
    const day_count = (day * 185 + 3498) * 185;
    const use_count = month_count + day_count;
    setCount(use_count);


  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      setUser(prevCount => prevCount + 1);
    }, 15000); // 100ms 간격으로 증가

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [user]);


  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    const timers = [2000, 3000, 5000];

    const interval = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, timers[index]); // 100ms 간격으로 증가

    const timerSwitch = setTimeout(() => {
      setIndex(prevCount => {
        const newIndex = (prevCount + 1) % 3;
        return newIndex;
      });
    });

    return () => {
      clearInterval(interval);
      clearTimeout(timerSwitch);
    };
  }, [count]);




  return (
    <>
      <RequestPage>
        <StyledGridContainer>

          <TextCenterColumnFlex>
            <DisplayH2 size="small">
              <SpanBlue>
                <Trans i18nKey={common.request.title1} />
              </SpanBlue>
              <span>
                <Trans i18nKey={common.request.title2} />
              </span>
            </DisplayH2>
            <Spacing pixel="8" />
            <StyledParagraph size="large">
              <Trans i18nKey={common.request.desc} />
            </StyledParagraph>
            <RequestButton onClick={() => clickLikes()}>
              <ThumbDiv>
                <ThumbIcon src={IconThumbYellow} alt="Thumb Up Icon"/>
              </ThumbDiv>
              <HeadingColor size="xsmall" color={"#FFF"}>
                <span>
                  <Trans i18nKey={common.request.button + ' '} />
                </span>
                <span>
                  <Trans i18nKey={getNumber()} />
                </span>
              </HeadingColor>
              <ThumbDiv>
                <ThumbIcon src={IconThumbYellow} alt="Thumb Up Icon"/>
              </ThumbDiv>
            </RequestButton>
          </TextCenterColumnFlex>
          <CountSection>
            <CountBox>
              <IconText>
                <Icon src={IconPersonLineBlue} alt="User Icon" />
                <HeadingColor size="xxsmall" color={"#2A70F0"}>
                  <Trans i18nKey={common.request.user} />
                </HeadingColor>
              </IconText>
              <Spacing pixel="16" />
              <DisplayLeftAuto size="small">
                <Trans i18nKey={user.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
              </DisplayLeftAuto>
            </CountBox>
            <CountBox>
              <IconText>
                <Icon src={IconUserCheckBlue} alt="User Check Icon"/>
                <HeadingColor size="xxsmall" color={"#2A70F0"}>
                  <Trans i18nKey={common.request.count} />
                </HeadingColor>
              </IconText>
              <Spacing pixel="16" />
              <DisplayLeftAuto size="small">
                <Trans i18nKey={count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
              </DisplayLeftAuto>
            </CountBox>
          </CountSection>
        </StyledGridContainer>
      </RequestPage>
    </>
  )
}


export default Request;