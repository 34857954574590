import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel } from "@components/Text";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { FuncBtn, PriceBoxSection, PriceBtnBox } from "./Manager.style";
import main from "@src/lang/ko/apartment/main";
import { BorderBottomLine, HeadingColor, ImgWidth100, LabelColor, Layout128to96, Spacing, SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const Manager = () => {


  const [index, setIndex] = useState<number>(0);
  const handleIndex = (i: number) => {
    setIndex(i);
  }
  return (
    <Layout128to96>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.manager.title1}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.manager.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.manager.title2}`} />
          </span>
        </DisplayH2>

        <PriceBtnBox>
          {main.manager.btn.map((data, i) => (
            <>
              {i === index ?
                <FuncBtn color={'#2A70F0'} onClick={() => handleIndex(i)}>
                  <LabelColor size="small" color={'#FFF'}>
                    <Trans i18nKey={`${data.text}`} />
                  </LabelColor>
                </FuncBtn>
                :
                <FuncBtn color={"#EAF1FD"} onClick={() => handleIndex(i)}>
                  <LabelColor size="small" color={'#2A70F0'}>
                    <Trans i18nKey={`${data.text}`} />
                  </LabelColor>
                </FuncBtn>
              }
            </>
          ))}
        </PriceBtnBox>
        <Spacing pixel="32" />
        <HeadingColor size="xsmall" color={"#818181"}>
          <span>
            <Trans i18nKey={`${main.manager.btn[index].desc1}`} />
          </span>
          <span>
            <Trans i18nKey={`${main.manager.btn[index].descBold}`} />
          </span>
          <span>
            <Trans i18nKey={`${main.manager.btn[index].desc2}`} />
          </span>
        </HeadingColor>

        <PriceBoxSection>
          <ImgWidth100 src={main.manager.btn[index].img} alt="orbrohome service image" />
        </PriceBoxSection>
        <Spacing pixel="96" />
        <BorderBottomLine />

      </StyledGridContainer>
    </Layout128to96>

  )
}


export default Manager;