import { StyledGridColumnProps } from "@components/Grid/Grid.model";
import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const BoxUpSection = styled.div`
  display : grid;
  width : 100%;
  grid-template-columns: repeat(2, 1fr);
  
`;


export const BoxBottomSection = styled.div`
  display : grid;
  width : 100%;
  grid-template-columns: repeat(3, 1fr);
  
`;


export const BoxUp = styled.div<{img :string, position : number}>`

  display : flex;
  flex-direction : column;
  height : auto;

  background-size : cover;
  background-position : center;
  ${({img})=> img&& `background-image : url(${img});`}

  ${({position})=> position === 0 ? 'aspect-ratio : 600/480;' : 'aspect-ratio : 390/480;'}
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    height : auto;
    ${({position})=> position === 2 ? 'aspect-ratio : 912/480;' : 'aspect-ratio : 440 / 480;'}

  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : auto;
    aspect-ratio : 361 / 480;
  }

`;


export const BoxBottom = styled.div`
  flex 1;

  height : 480px;
  display : flex;
  flex-direction : column;

`;

export const BoxText = styled.div`
  padding : 32px;
  gap : 8px;
  display : flex;
  flex-direction : column;
  height : 30%;
`;

export const BoxImgSection = styled.div`
  max-width : 100%;
  max-height : 100%;
  height : 70%;
  display : flex;
  obejct-fit : contain;
`;

export const BoxImg = styled.img`

  max-width : 100%;
  height : auto;
  
`;


export const NewTag = styled.div`
  padding : 4px 8px;
  border-radius: 4px;
  background: rgba(0, 195, 125, 0.08);
  width : fit-content;
`;