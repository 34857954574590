import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledLabel, StyledParagraph } from "@components/Text";
import { CommonTextProps } from "@components/Text/CommonText.style";
import { TypeMap } from "@core/util";
import styled from "@emotion/styled";

type SizeTypes = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
interface StyledParagraphProps extends CommonTextProps {
  size: SizeTypes;
}

type ParagraphData = {
  size: string,
  height: string,
}
const sizeMap: TypeMap<SizeTypes, ParagraphData> = {
  xlarge: { size: '20px', height: '30px' },
  large: { size: '18px', height: '1.56' },
  medium: { size: '16px', height: '1.5' },
  small: { size: '14px', height: '1.43' },
  xsmall: { size: '12px', height: '1.33' },
};



export const ApartmentPage = styled.div`


& * {
    font-family: Pretendard !important;
  }


`

export const Layout128to96 = styled.div`

margin : 128px 0 ;

${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  margin : 96px 0;
}

`;


export const ParagraphH1 = styled.h1<StyledParagraphProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  color: #000;
  
  font-style : normal;
  font-weight : 400;
`;

export const ParagraphH2 = styled.h2<StyledParagraphProps>`
  font-size: ${({ size }) => sizeMap[size].size};
  line-height: ${({ size }) => sizeMap[size].height};
  color: #000;
  
  font-style : normal;
  font-weight : 400;
`;


export const SpanBlue = styled.span`
  color : #2A70F0;
`;
export const SpanBlueUnderline = styled.span`
  color : #2A70F0;
  text-decoration-line : underline;
`;

export const SpanFFF = styled.span`
  color : #FFFFFF;
`;

export const Span818181 = styled.span`
  color : #818181;
`;
export const Span00C37D = styled.span`
  color : #00C37D;
`;
export const SpanFF6C61 = styled.span`
  color : #FF6C61;
`;


export const Img32 = styled.img`
  width : 32px;
  height : 32px;
`;

export const ImgWidth100 = styled.img`
  width : 100%;
  max-width : 1232px;
  aspect-ratio : auto 1232 / 771;
`;


export const Spacing = styled.div<{pixel : string}>`

  ${({pixel}) => pixel && `margin-top : ${pixel}px`};

`;

export const DisplayColor = styled(StyledDisplay)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`;

export const HeadingColor = styled(StyledHeading)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`;

export const LabelColor = styled(StyledLabel)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`

export const ParagraphColor = styled(StyledParagraph)<{color : string}>`
  ${({color})=> color && `color : ${color};`}
`



export const BorderBottomLine = styled.div`
  border-bottom : 1px solid rgba(0,0,0,0.08);
`;