import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import React from "react";
import { Trans } from "react-i18next";
import { ImgBox, InnerTextBox } from "./Upgrade.style";
import main from "@src/lang/ko/apartment/main";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { BorderBottomLine, HeadingColor, Layout128to96, Spacing, Span818181, SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const Upgrade = () => {

  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <Layout128to96>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.upgrade.title1}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <span>
            <Trans i18nKey={`${main.upgrade.title2}`} />
          </span>
          <SpanBlue>
            <Trans i18nKey={`${main.upgrade.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.upgrade.title3}`} />
          </span>
        </DisplayH2>

        <Spacing pixel="32"/>
        <StyledHeading size="xsmall">
          <Span818181>
            <Trans i18nKey={`${main.upgrade.desc1}`} />
          </Span818181>
          <span>
            <Trans i18nKey={`${main.upgrade.descBold1}`} />
          </span>
          <Span818181>
            <Trans i18nKey={`${main.upgrade.desc2}`} />
          </Span818181>
          <span>
            <Trans i18nKey={`${main.upgrade.dsecBold2}`} />
          </span>
          <Span818181>
            <Trans i18nKey={`${main.upgrade.desc3}`} />
          </Span818181>
        </StyledHeading>

        <Spacing pixel="48"/>
        <StyledGridRow>
          {main.upgrade.img.map((data, i) => (
            <StyledGridColumn desktop={6} tablet={4} mobile={4} key={i}>
              <ImgBox img={main.upgrade.img[i].img[layoutType]}>
                <InnerTextBox>
                  <HeadingColor size="xxxxsmall" color={"#646464"}>
                    <Trans i18nKey={`${data.tag}`} />
                  </HeadingColor>
                  <StyledHeading size="medium">
                    <Trans i18nKey={`${data.title}`} />
                  </StyledHeading>
                </InnerTextBox>
              </ImgBox>
            </StyledGridColumn>
          ))}

        </StyledGridRow>

        <Spacing pixel="96"/>
        <BorderBottomLine/>

      </StyledGridContainer>
    </Layout128to96>

  )
}


export default Upgrade;