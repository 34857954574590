import { StyledHeading } from "@components/Text";
import { TextCenterColumnFlex } from "@pages/Apartment/Banner/Banner.style";
import common from "@src/lang/ko/event/common";
import React from "react";
import { Trans } from "react-i18next";
import { DataBox, HeadingXXXXsmall, PriceBorderBottom, PriceBottomSection, PriceBox, PriceChartSection, PriceImgText, PricePage, PriceTextBox, Showmore } from "./Price.style";
import { IconArrowRightTailBlue } from "@assets/images/icon";
import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { InfoGrid1 } from "../Info/Info.style";
import { LabelColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import { DisplayH2 } from "../Banner/Banner.style";


import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";

const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}


interface apart {
  apartName: string;
}

const Price: React.FC<apart> = ({ apartName }) => {

  const path = list[apartName];
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = currentDate.getDate();

  // 년-월-일 형식으로 표시
  const formattedDate = '(' + `${year}년 ${month < 10 ? '0' + month : month}월 ${day < 10 ? '0' + day : day}` + '일 기준)';


  return (
    <StyledGridContainer>
      <PricePage>
        <TextCenterColumnFlex>
          <DisplayH2 size="small">
            <span>
              <Trans i18nKey={`${apartName}.name`} />
            </span>
            <SpanBlue>
              <Trans i18nKey={common.price.title} />
            </SpanBlue>
          </DisplayH2>
          <Spacing pixel="16" />
          <LabelColor size="medium" color={"#818181"}>
            <Trans i18nKey={formattedDate} />
          </LabelColor>
        </TextCenterColumnFlex>

        <StyledGridRow>
          <InfoGrid1 desktop={1} tablet={0} mobile={0} />
          <StyledGridColumn desktop={10} tablet={8} mobile={4}>
            <PriceChartSection>
              {common.price.company.map((data, i) => (
                <>
                  <PriceBox>
                    <PriceImgText>
                      <img src={data.icon} alt="App Icon" />
                      <PriceTextBox>
                        <StyledHeading size="small">
                          <Trans i18nKey={data.title} />
                        </StyledHeading>
                        {path.price.company[i].link !== 'no' &&
                          <>
                            <Showmore
                              href={`${path.price.company[i].link}`}
                              target="_blank"
                              rel="noopener"
                              text={path.price.company[i].link}>
                              <LabelColor size="medium" color={"#2A70F0"}>
                                <Trans i18nKey={common.more} />
                              </LabelColor>
                              <img src={IconArrowRightTailBlue} alt="Blue Arrow Icon" />
                            </Showmore>
                          </>
                        }
                      </PriceTextBox>
                    </PriceImgText>

                    <PriceBottomSection>

                      <>
                        {path.price.company[i].data.map((text, k) => (
                          <DataBox key={k} n={k}>
                            <HeadingXXXXsmall>
                              <Trans i18nKey={text.size} />
                            </HeadingXXXXsmall>
                            <HeadingXXXXsmall>
                              <Trans i18nKey={text.price} />
                            </HeadingXXXXsmall>
                            <HeadingXXXXsmall>
                              <Trans i18nKey={text.competition} />
                            </HeadingXXXXsmall>
                          </DataBox>
                        ))}
                      </>


                    </PriceBottomSection>


                  </PriceBox>
                </>
              ))}

            </PriceChartSection>
            <PriceBorderBottom />
          </StyledGridColumn>
        </StyledGridRow>
      </PricePage>
    </StyledGridContainer>
  )
}


export default Price;