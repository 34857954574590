import React from "react";
import SEO from "@pages/SEO";
import Banner from "./Banner/Banner";
import OurPartners from "./Partners/OurPartners";
import Service from "./Service/Service";
import Elevator from "./Elevator/Elevator";
import Secure from "./Secure/Secure";
import Price from "./Price/Price";
import Manager from "./Manager/Manager";
import Upgrade from "./Upgrade/Upgrade";
import OneService from "./OneService/OneService";
import Exam from "./Exam/Exam";
import Customer from "./Customer/Customer";
import Onepass from "./Onepass/Onepass";
import BottomMenu from "./BottomMenu/BottomMenu";
import { ApartmentPage } from "./Apartment.style";
import seo from "@src/lang/ko/seo";
import { Helmet } from "react-helmet";



const helmetTitle = seo.default.title;
const ogKey = seo.default.key;
const ogDesc = seo.default.desc;
const ogImg = seo.default.img;
const ogUrl = seo.default.url;



const Apartment = () => {

  return (
    <>
      <SEO
        lang={'ko'}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
      />
      <Helmet>
        <meta name="naver-site-verification" content="c1fecfbe46607c33389bab8f8b5e889de0103621" />
        <meta name="google-site-verification" content="U63on-H0ro4G0EMQHUAGFlSyV2iGPko-uZdKB9PWf-Q" />
      </Helmet>

      <ApartmentPage>
        <Banner />
        <OurPartners />
        <Onepass />
        <Service />
        <Elevator />
        <Secure />
        <Price />
        <Manager />
        <Upgrade />
        <OneService />
        <Exam />
        <Customer />
        <BottomMenu />
      </ApartmentPage>
    </>
  )
};

export default Apartment;