import { StyledGridContainer } from "@components/Grid/Grid.style"
import React, { useEffect, useState } from "react"
import { AppBtn, Appbox, FallTextBox, Falldown, FalldownBox, ProgressBar, ProgressBar2, RightImg, SelectBox, SelectBtn, SerivcePage, ServiceLeftSide, ServiceRightSide } from "./Service.style"
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text"
import { Trans } from "react-i18next"

import main from "@src/lang/ko/apartment/main"
import { IconAppStore, IconGooglePlay } from "@assets/images/apartment"
import { GrayLeftButton, GrayRightButton } from "@assets/images/apartment/icon"
import { useSelector } from "react-redux"
import { CommonState } from "@redux/reducers/commonReducer"
import { HeadingColor, Spacing, SpanBlue } from "../Apartment.style"
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style"




const Service = () => {

  const [index, setIndex] = useState<number>(0);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const handleText = (i: number) => {
    if (i === 4) {
      setIndex(0);
    } else if (i < 0) {
      setIndex(2)
    } else {
      setIndex(i);
    }
  }

  useEffect(() => {
    if (layoutType === 'desktop') {

      const intervalId = setInterval(() => {
        if (index === 3) {
          handleText(0);
        } else {
          handleText(index + 1);
        }
      }, 4000);

      // 컴포넌트가 언마운트되면 인터벌 클리어
      return () => clearInterval(intervalId);

    }
  }, [index, handleText]);


  return (
    <>
      <StyledGridContainer>
        <SerivcePage>
          <ServiceLeftSide>
            <div>
              <DisplayH2 size="small">
                <Trans i18nKey={`${main.service.left.title1}`} />
              </DisplayH2>
              <StyledDisplay size="small">
                <SpanBlue>
                  <Trans i18nKey={`${main.service.left.titleBold}`} />
                </SpanBlue>
                <span>
                  <Trans i18nKey={`${main.service.left.title2}`} />
                </span>
              </StyledDisplay>

              <Spacing pixel="16"/>
              <StyledParagraph size="large">
                <Trans i18nKey={`${main.service.left.desc}`} />
              </StyledParagraph>
              <Appbox>
                <AppBtn src={IconAppStore} alt="app store image" width={"120"}/>
                <AppBtn src={IconGooglePlay} alt="google play store image" width={"136"}/>
              </Appbox>

              <Spacing pixel="40"/>

              <Falldown>
                {main.service.left.fall.map((data, i) => (
                  <>
                    {i === index ?
                      <FalldownBox>
                        <ProgressBar h={"56"}>
                          <ProgressBar2 />
                        </ProgressBar>
                        <FallTextBox onClick={() => handleText(i)}>
                          <StyledHeading size="xsmall"> <Trans i18nKey={`${data.title}`} /> </StyledHeading>
                          <StyledParagraph size="medium"> <Trans i18nKey={`${data.desc}`} /> </StyledParagraph>
                        </FallTextBox>
                      </FalldownBox>
                      :
                      <FalldownBox>
                        <ProgressBar h={"28"} />
                        <FallTextBox onClick={() => handleText(i)}>
                          <HeadingColor size="xsmall" color={"#BDBDBD"}> <Trans i18nKey={`${data.title}`} /> </HeadingColor>
                        </FallTextBox>
                      </FalldownBox>
                    }
                  </>
                ))}
              </Falldown>

            </div>
          </ServiceLeftSide>

          <SelectBox>
            <SelectBtn direction={0} onClick={() => handleText(index - 1)}>
              <img src={GrayLeftButton} alt="Service Select Button" />
            </SelectBtn>
            {main.service.left.fall.map((data, i) => (
              <>
                {i === index &&
                  <FallTextBox>
                    <StyledHeading size="xsmall"> <Trans i18nKey={`${data.title}`} /> </StyledHeading>
                    <StyledParagraph size="medium"> <Trans i18nKey={`${data.desc}`} /> </StyledParagraph>
                  </FallTextBox>
                }
              </>
            ))}
            <SelectBtn direction={1} onClick={() => handleText(index + 1)}>
              <img src={GrayRightButton} alt="Service Select Button" />
            </SelectBtn>
          </SelectBox>


          <ServiceRightSide>
            <RightImg src={main.service.left.fall[index].img} alt="orbrohome app display"/>
          </ServiceRightSide>


        </SerivcePage>


      </StyledGridContainer>


    </>


  )

}


export default Service;