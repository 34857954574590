import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const InfoPage = styled.div`
    
    padding : 128px 0 64px 0;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0;
    }

`;

export const InfoLogo = styled.div`
    height : 25px;
    width : 100%;
    justify-content : center;
    align-items : center;
    display : flex;

    margin-bottom : 16px;

    }


    & img {
        width : 80px;
        height : 25px;
        object-fit : contain;
    }

`;

export const InfoChart = styled.div<{number : number}>`
    
    border-bottom : 1px solid rgba(0,0,0,0.08);
    display: flex;
    padding: 24px 0px;

    width: 100%;

    flex-direction : row;

    ${({number})=> number === 0 ? 'border-top : 1px solid rgba(0,0,0,0.08);' : ''}
`;


export const InfoChartl = styled.div`
    width : 27.7%;
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        width : 35.3%;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        min-width : 27.7%;
    }
`;

export const InfoChartR = styled.div`

`;


export const InfoGrid1 = styled(StyledGridColumn)`

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        display:none;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display:none;
    }
`;