import { BannerCheongjuParklaneD, BannerCheongjuParklaneM, BannerCheongjuParklaneT, LogoCheongjuParklane, NavCheongjuParklane } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerCheongjuParklaneD,
    tablet : BannerCheongjuParklaneT,
    mobile : BannerCheongjuParklaneM,
  },
  logo : LogoCheongjuParklane,
  nav : NavCheongjuParklane,
  name: '청주 동일하이빌 파크레인',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',
  
  a : 36.6213505,
  b : 127.4584468,

  info : [
    {
      data : '충청북도 청주시 서원구 개신동 70-10'
    },
    {
      data : '총 800 세대',
    },
    {
      data : '84㎡A, 84㎡B, 84㎡C, 112', 
    },
    {
      data : '(주)동일토건'
    },
    {
      data : '주식회사 하나자산신탁',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '탕정역세권',
      desc: '1호선 탕정역 도보 10분, 천안아산역 KTX, SRT, 아산, 천안고속도로 인접'
    },
    {
      icon: Icon3Dsquare,
      title: '중심생활권',
      desc: '백화점, 대형마트 등 다양한 생활편의시설과 용곡공원, 지산공원, 곡교천'
    },
    {
      icon: Icon3Dlife,
      title: '직주근접 생활환경',
      desc: '약 4,300세대 규모의 아산탕정지구 도시개발구역 예정'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 학군',
      desc: '유치원, 초등학교, 중학교가 단지 앞 한걸음.'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fADda/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '4억 1,969',
            competition: '-',
          },
          {
            size: '84A',
            price: '4억 1,680',
            competition: '-',
          },
          {
            size: '84C',
            price: '4억 2,817',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/89494?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '3억 9,470',
            competition: '6:1',
          },
          {
            size: '84B',
            price: '3억 9,700',
            competition: '4.9:1',
          },
          {
            size: '84C',
            price: '3억 9,730',
            competition: '3.9:1',
          },
        ]
      },
      {
        link : 'https://m.land.naver.com/complex/info/157341?ptpNo=1',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '-',
            competition: '',
          },
          {
            size: '84B',
            price: '-',
            competition: '',
          },
          {
            size: '84C',
            price: '-',
            competition: '',
          },
        ]
      },

    ]

  },

  map: '충청북도 청주시 서원구 개신동 70-10'


}