import { BannerGeomdanJeilD, BannerGeomdanJeilM, BannerGeomdanJeilT, BannerOsansegyoParagonD, BannerOsansegyoParagonM, BannerOsansegyoParagonT, BannerUnjeongLynnD, BannerUnjeongLynnM, BannerUnjeongLynnT, LogoGeomdanJeil, LogoOsansegyo, LogoUnjeongLynn, NavGeomdanJeil, NavOsansegyo, NavUnjeongLynn } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerGeomdanJeilD,
    tablet : BannerGeomdanJeilT,
    mobile : BannerGeomdanJeilM
  },
  logo : LogoGeomdanJeil,
  nav : NavGeomdanJeil,
  name: '제일풍경채 검단 IV',
  likes : 1879,
  user: '468,511',
  count: '15,445,432',
  
  a : 37.6149843,
  b : 126.6984974,

  info : [
    {
      data : '인천광역시 서구 불로동 204-5'
    },
    {
      data : '총 1,048 세대',
    },
    {
      data : '84㎡A·B·C, 110㎡A·B', 
    },
    {
      data : '제일건설(주)'
    },
    {
      data : '인천검단3차피에프브이(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '빠른 교통망',
      desc: '인천 1호선(102역 예정), 5호선 불로역(추진중) 등 다양한 교통호재로 더 빠른 광역 교통망'
    },
    {
      icon: Icon3Dsquare,
      title: '인프라 생활',
      desc: '커낼콤플렉스(예정), 홈플러스, 단지 내 상가, 실내 체육관 등 다양한 생활 인프라'
    },
    {
      icon: Icon3Dlife,
      title: '생활 프리미엄',
      desc: '대규모 12호 근린공원(예정), 경관녹지 인접, 브랜드타운 형성 예정 등의 호재'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 학군',
      desc: '단지 내 어린이집과 인근 유치원, 초등학교, 중학교로 안심할 수 있는 원스톱 도보통학'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/glIde/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '5억 4,900',
            competition: '발표 예정',
          },
          {
            size: '84B',
            price: '5억 4,100',
            competition: '발표 예정',
          },
          {
            size: '110A',
            price: '6억 8,500',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91193?checking_subscription=true',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84A',
            price: '5억 4,900',
            competition: '발표 예정',
          },
          {
            size: '84C',
            price: '5억 3,600',
            competition: '발표 예정',
          },
          {
            size: '110B',
            price: '6억 8,800',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '인천광역시 서구 불로동 204-5'


}