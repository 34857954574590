import { GRAY_50,TRANSPARENT } from "@assets/styled/color.style";
import { FlexStyle, Vertical } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledLabel, StyledParagraph } from "@components/Text";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledFooter = styled(FlexStyle)`
  width: 100%;
  justify-content: center;
  background: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
  z-index: 2;
  padding-top : 64px;

  & * {
    font-family: Pretendard !important;
  }
  
`;

export const StyledFooterLogo = styled.img`
  width: 130px;
  aspect-ratio: auto 130 / 30;
  object-fit: cover;
`;

export const StyledFooterSubTitle = styled(StyledLabel)`
  color: ${({ theme }) => (theme.CONTENT_PRIMARY)};
`

export const StyledFooterHead = styled(StyledGridRow)`
  padding-top: 64px;
  padding-bottom: 52px;


  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-bottom: 64px;
    row-gap: 0;
  }
`;

export const StyledFooterHeadColumn = styled(StyledGridColumn)`
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 48px;
  }
`;

export const StyledFooterSub = styled(Vertical)`
  row-gap: 16px;
`;

export const StyledFooterSubItem = styled(StyledLabel)`
  color: ${({ theme }) => (theme.CONTENT_SECONDARY)};
`;

export const StyledFooterBottom = styled(Vertical)`
  border-top: 1px solid ${({ theme }) => (theme.BORDER_TRANSLUCENT)};
  padding: 24px 0 52px;
`

export const StyledFooterBottomRow = styled(FlexStyle)`
  
`;

export const StyledFooterBottomTitle = styled(FlexStyle)`
  height: 60px;
  align-items: center;
`;

export const StyledFooterLink = styled(Link)`
  color: ${({ theme }) => (theme.CONTENT_SECONDARY)};
`;

export const StyledFooterBottomParagraph = styled(StyledParagraph)`
  color: ${({ theme }) => (theme.CONTENT_SECONDARY)};
`;

export const StyledFooterATag = styled.a`
  cursor : pointer;
  color: ${({ theme }) => (theme.CONTENT_TERTIARY)};
`;





export const StyledFooterHeadColumn40gap = styled(StyledGridColumn)`
    display: flex;
    flex-direction: column;
    gap: 40px;
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    gap : 0px;
  }
`;


export const StyledMobileSpacing48 = styled.div`

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding-top : 48px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding-top : 48px;
  }

`

export const A646464 = styled.a`
  color : #646464;
`