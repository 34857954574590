import {  BannerMunjeongHillstateD, BannerMunjeongHillstateM, BannerMunjeongHillstateT, LogoImunipark, LogoMunjeongHillstate, NavImunipark, NavMunjeongHillstate } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerMunjeongHillstateD,
    tablet : BannerMunjeongHillstateT,
    mobile : BannerMunjeongHillstateM
  },
  logo : LogoMunjeongHillstate,
  nav : NavMunjeongHillstate,
  name: '문정 힐스테이트 e편한세상',
  likes : 1461,
  user: '468,511',
  count: '15,445,432',

  a : 37.48881,
  b : 127.133737,

  info : [
    {
      data : '서울특별시 송파구 문정동 136번지 일원'
    },
    {
      data : '총 1,265 세대',
    },
    {
      data : '49㎡A, 49㎡B, 59㎡A, 59㎡B, 74㎡A, 74㎡C,D, 74㎡E,F,', 
    },
    {
      data : '현대엔지니어링(주), DL e&c'
    },
    {
      data : '문정동 136번지일원 재건축정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '자유로운 쾌속교통',
      desc: '수도권제1순환도로, 송파IC, 지하철(3,5,8호선) 및 SRT 수서역, 장지동길지하차도 등 다양한 교통환경'
    },
    {
      icon: Icon3Dsquare,
      title: '여유로운 송파생활',
      desc: '이마트, NC백화점, 스타필드, 가든파이브, 등의 인프라 및 문정근린공원, 장지공원, 송파둘레길 등의 힐링까지 갖춘 생활'
    },
    {
      icon: Icon3Dlife,
      title: '경이로운 미래문정',
      desc: '사업지 인근 재건축, 리모델링을 통한 주거비전과 수서역세권 개발(예정), 복정역 스마트시티(계획)의 수혜 기대'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 교육환경',
      desc: '문정초, 문정중, 문정고, 한림예고 등 도보거리 학군을 갖춘 안심 교육환경'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dQw05/0',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '68A / 49A',
            price: '7억 6,580',
            competition: '63.2 : 1',
          },
          {
            size: '67B / 49B',
            price: '7억 5,380',
            competition: '71.1 : 1',
          },
          {
            size: '80B / 59B',
            price: '8억 8,460',
            competition: '<strong>354.3 : 1</strong>',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/80704',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '68A / 49A',
            price: '7억 6,580',
            competition: '63.2 : 1',
          },
          {
            size: '67B / 49B',
            price: '7억 5,380',
            competition: '71.1 : 1',
          },
          {
            size: '80B / 59B',
            price: '8억 8,460',
            competition: '<strong>354.3 : 1</strong>',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6024171&supp_cd=9031188&SYMap=37.48881,127.133737,16&a=&b=C12',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '68A / 49A',
            price: '7억 6,580',
            competition: '63.2 : 1',
          },
          {
            size: '67B / 49B',
            price: '7억 5,380',
            competition: '71.1 : 1',
          },
          {
            size: '80B / 59B',
            price: '8억 8,460',
            competition: '<strong>354.3 : 1</strong>',
          },
        ]
      },

    ]

  },

  map: '서울특별시 송파구 문정동 136번지 일원'


}