import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import './Default.styl';
import Footer from './components/Footer/Footer';
import NavBox from './components/NavBox/NavBox';
import NavBoxEvent from './components/NavBox/NavBoxEvent';


const Default = () => {

  const location = useLocation();

  const checkPage = () => {
    if (location.pathname.includes('/apart/')) {
      return true;
    } else {
      return false;
    }
  }


  const renderNavBox = () => {
    if (checkPage()) {
    } else {
      return  <><NavBoxEvent /><NavBox /></>
    }
  }


  const renderFooter = () => {
    if (checkPage()) {
      return <Footer />
    } else {
      return <Footer />
    }
  }




  return (
    <div className="default-layout">
      {renderNavBox()}
      <div className="contents">
        <Outlet />
      </div>

      {renderFooter()}
    </div>
  );
};

export default Default;
