import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const PointPage = styled.div`

    padding-top : 96px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding-top : 64px;
    }
`;

export const PointBorderBottom = styled.div`

    border-bottom : 1px solid rgba(0,0,0,0.08);

    padding-bottom : 128px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding-bottom : 96px;
    }

`;


export const PointSection = styled.div`
    display : grid;
    grid-template-columns : repeat(2, 1fr);
    gap : 32px;


    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex;
        flex-direction : column;
        gap : 32px;
    }


`;

export const PointBox =styled.div<{layout:string, n: number}>`
    display : flex;
    flex-direction : row;
    gap : 24px;

    & img {
        object-fit : contain;
    }
    height : fit-content;

    ${({layout, n})=> layout !== 'mobile' && n > 1 ? 'margin-top : 32px;' : ''}

`;


export const PointImg = styled.img`
    width : 100px;
    height : 100px;
    margin-bottom : 36px;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        width : 80px;
        height : 80px;
        margin-bottom : 0px;
    }

`;

export const PointTextBox = styled.div`
    display : flex;
    flex-direction : column;
    padding-right : 24px;
    gap : 8px;
    margin-bottom : 12px;
`;