import { BannerSeosanIparkD, BannerSeosanIparkM, BannerSeosanIparkT, BannerUnjeongLynnD, BannerUnjeongLynnM, BannerUnjeongLynnT, LogoSeosanIpark, LogoUnjeongLynn, NavSeosanIpark, NavUnjeongLynn } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerSeosanIparkD,
    tablet : BannerSeosanIparkT,
    mobile : BannerSeosanIparkM
  },
  logo : LogoSeosanIpark,
  nav : NavSeosanIpark,
  name: '서산 센트럴 아이파크',
  likes : 1879,
  user: '468,511',
  count: '15,445,432',
  
  a : 36.7740623,
  b : 126.4644719,

  info : [
    {
      data : '충청남도 서산시 석림동 528-1'
    },
    {
      data : '총 410 세대',
    },
    {
      data : '84㎡·101㎡·116㎡', 
    },
    {
      data : 'HDC현대산업개발(주)'
    },
    {
      data : 'HDC현대산업개발(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '최적의 입지조건',
      desc: '서해로, 중앙로, 서산IC 등 어디든 빠르게 통하는 편리한 교통환경을 제공합니다.'
    },
    {
      icon: Icon3Dsquare,
      title: '라이프 스타일',
      desc: '창의적이고 혁신적 생각을 중요시하는 HDC현대산업개발(주)는 단순한 주거공간을 넘는 최적의 라이프스타일을 제공하고자 합니다.'
    },
    {
      icon: Icon3Dlife,
      title: '브랜드 타운',
      desc: '서산 센트럴 아이파크 단지는 약 1,070㎡ 규모의 중앙광장 및 안전하고 넉넉한 100% 지하주차장을 제공합니다.'
    },
    {
      icon: Icon3Dsecure,
      title: '주거 가치',
      desc: '시간이 더 지날 수록 가치있는 서산 센트럴 아이파크는 거주자의 편안한 생활을 위해 최고의 기술력을 바탕으로 하여 시공되었습니다.'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dq819/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/87935',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '충청남도 서산시 석림동 528-1'


}