import { BannerThepoleD, BannerThepoleM, BannerThepoleT, LogoThepole, NavThepole } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerThepoleD,
    tablet : BannerThepoleT,
    mobile : BannerThepoleM
  },
  logo : LogoThepole,
  nav : NavThepole,
  name: '더폴 디오션',
  likes : 1711,
  user: '468,511',
  count: '15,445,432',

  a : 35.183568,
  b : 129.204284,

  info : [
    {
      data : '부산광역시 해운대구 송정동 201-1'
    },
    {
      data : '총 184 세대',
    },
    {
      data : '59㎡, 67㎡, 74㎡, 84㎡', 
    },
    {
      data : '(주)우성종합건설'
    },
    {
      data : '케이비부동산신탁(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '해운대 생활권',
      desc: '백화점, 병원, 재래시장, 학교, 학원가 등 해운대신도시 생활권을 누리는 입지'
    },
    {
      icon: Icon3Dsquare,
      title: '컬쳐 라이프',
      desc: '롯데월드 어드벤처, 아난티, 힐튼호텔, 이케아, 등 고품격 해양 관광단지'
    },
    {
      icon: Icon3Dlife,
      title: '눈앞에 오션 라이프',
      desc: '바다와 송정천, 망덕봉 등의 자연경관과 송정해수욕장을 가까이 누리는 특별한 생활'
    },
    {
      icon: Icon3Dsecure,
      title: '오시리아 미래가치',
      desc: '관광단지 개발로 더욱 가치가 높아지는 부산의 새로운 랜드마크 입지'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fDjf2/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '67A',
            price: '5억 400',
            competition: '-',
          },
          {
            size: '84A',
            price: '6억 4,900',
            competition: '-',
          },
          {
            size: '84C',
            price: '6억 5,500',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/89649',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84A / 59A',
            price: '4억 5,200',
            competition: '발표 예정',
          },
          {
            size: '103A / 74A',
            price: '5억 5,400',
            competition: '발표 예정',
          },
          {
            size: '118B / 84B',
            price: '6억 5,000',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6026433&supp_cd=9036545&DetailType=Complex&SYMap=35.183568,129.204284,16&a=',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '67A',
            price: '5억 400',
            competition: '-',
          },
          {
            size: '84A',
            price: '6억 4,900',
            competition: '-',
          },
          {
            size: '84C',
            price: '6억 5,500',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '부산광역시 해운대구 송정동 201-1'


}