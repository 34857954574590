import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const MapPage = styled.div`
    padding : 128px 0;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0;
    }
`;


export const MapSize = styled.div`
    width : 100%;
    height : 480px;
    aspect-ratio : 1920 / 480 ;
    border-radius : 20px;
`;

export const MapCss = styled.div`
    padding : 20px;
    
`;