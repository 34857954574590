import { BannerZenithCentralD, BannerZenithCentralM, BannerZenithCentralT, LogoZenithCentral, NavZenithCentral } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerZenithCentralD,
    tablet : BannerZenithCentralT,
    mobile : BannerZenithCentralM,
  },
  logo : LogoZenithCentral,
  nav : NavZenithCentral,
  name: '두산위브더제니스 센트럴 계양',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',

  a : 37.5281966,
  b : 126.7198168,

  info : [
    {
      data : '인천광역시 계양구 작전동 439-7'
    },
    {
      data : '총 1,370 세대',
    },
    {
      data : '49㎡, 59㎡A, 59㎡B, 74㎡B', 
    },
    {
      data : '두산건설(주), 쌍용건설(주)'
    },
    {
      data : '작전현대아파트구역재개발정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '쾌속 교통 프리미엄',
      desc: '인천1호선 작전역 역세권, 경인고속도로 등'
    },
    {
      icon: Icon3Dsquare,
      title: '지역 호재',
      desc: '서울 2호선 청라역 연장(계획), GTX-B(계획), 두산건설이 짓는 최상위 주거 브랜드'
    },
    {
      icon: Icon3Dlife,
      title: '삶의 질을 높이는 인프라',
      desc: '홈플러스, 이마트, 한림병원, CGV 등'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 학군',
      desc: '도보거리 성지초, 명현중, 효성고 등'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fwFb5/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '49㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59A㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59B㎡',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/80131?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '49㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59A㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59B㎡',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '49㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59A㎡',
            price: '-',
            competition: '발표 예정',
          },
          {
            size: '59B㎡',
            price: '-',
            competition: '발표 예정',
          },
        ]
      },

    ]

  },

  map: '인천광역시 계양구 작전동 439-7'


}