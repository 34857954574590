
import React from "react";
import { PointBorderBottom, PointBox, PointImg, PointPage, PointSection, PointTextBox } from "./Point.style";
import { TextCenter } from "@pages/Apartment/Banner/Banner.style";
import { StyledHeading } from "@components/Text";
import { Trans } from "react-i18next";
import common from "@src/lang/ko/event/common";
import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { InfoGrid1 } from "../Info/Info.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { ParagraphColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import { DisplayH2 } from "../Banner/Banner.style";


import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";

const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}


interface apart {
  apartName: string;
}

const Point: React.FC<apart> = ({ apartName }) => {

  const path = list[apartName];
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <>
      <StyledGridContainer>
        <PointPage>
          <TextCenter>
            <DisplayH2 size="small">
              <span>
                <Trans i18nKey={`${apartName}.name`} />
              </span>
              <SpanBlue>
                <Trans i18nKey={common.point} />
              </SpanBlue>
            </DisplayH2>
          </TextCenter>

          <Spacing pixel="64" />

          <StyledGridRow>
            <InfoGrid1 desktop={1} tablet={0} mobile={0} />
            <StyledGridColumn desktop={10} tablet={8} mobile={4}>
              <PointSection>
                {path.point.map((data, i) => (
                  <>
                    <PointBox layout={layoutType} n={i}>
                      <PointImg src={data.icon} alt="Function 3D Icon"/>
                      <PointTextBox>
                        <StyledHeading size="small">
                          <Trans i18nKey={data.title} />
                        </StyledHeading>
                        <ParagraphColor size="medium" color={"#646464"}>
                          <Trans i18nKey={data.desc} />
                        </ParagraphColor>
                      </PointTextBox>

                    </PointBox>
                  </>
                ))}
              </PointSection>
              <PointBorderBottom />
            </StyledGridColumn>
          </StyledGridRow>
        </PointPage>
      </StyledGridContainer>
    </>
  )
}


export default Point;