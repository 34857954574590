import { BannerDongraeLotteCastleD, BannerDongraeLotteCastleM, BannerDongraeLotteCastleT, LogoDongraeLotteCastle, NavDongraeLotteCastle } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerDongraeLotteCastleD,
    tablet : BannerDongraeLotteCastleT,
    mobile : BannerDongraeLotteCastleM,
  },
  logo : LogoDongraeLotteCastle,
  nav : NavDongraeLotteCastle,
  name: '동래 롯데캐슬 시그니처',
  likes : 1391,
  user: '418,914',
  count: '15,195,432',
  
  a : 35.197773,
  b : 129.0862899,

  info : [
    {
      data : '부산광역시 동래구 수안동 36-2'
    },
    {
      data : '총 870 세대',
    },
    {
      data : '65㎡, 69㎡, 84㎡', 
    },
    {
      data : '롯데건설(주)'
    },
    {
      data : '-',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '부산의 중심, 동래구',
      desc: '중앙대로부터 원동IC, 제2만덕터널 등 부산 도심 어디로든 광역 이동이 편리한 부산의 중심'
    },
    {
      icon: Icon3Dsquare,
      title: '트리플 역세권의 중심',
      desc: '지하철 1호선 교대역, 4호선 수인역과 동해선 동래역을 빠르게 누리는 트리플 역세권'
    },
    {
      icon: Icon3Dlife,
      title: '편리한 일상의 시작',
      desc: '주민센터, 세무서, 구청, 보건소 등 관공서와 마트, 백화점, 공원 등의 풍부한 생활 인프라'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 동래구의 가치',
      desc: '동래구 생활복합시설 건립(예정), 만덕-센텀대심도(예정) 등으로 달라질 눈부신 미래가치'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/gtp42/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '65㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '69㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91945?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '65㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '69㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '65㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '69㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '부산광역시 동래구 수안동 36-2'


}