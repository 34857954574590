import { BannerOsansegyoParagonD, BannerOsansegyoParagonM, BannerOsansegyoParagonT, BannerUnjeongLynnD, BannerUnjeongLynnM, BannerUnjeongLynnT, LogoOsansegyo, LogoUnjeongLynn, NavOsansegyo, NavUnjeongLynn } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerOsansegyoParagonD,
    tablet : BannerOsansegyoParagonT,
    mobile : BannerOsansegyoParagonM
  },
  logo : LogoOsansegyo,
  nav : NavOsansegyo,
  name: '오산세교 파라곤',
  likes : 1879,
  user: '468,511',
  count: '15,445,432',
  
  a : 37.1614591,
  b : 127.0486488,

  info : [
    {
      data : '경기도 오산시 궐동 439'
    },
    {
      data : '총 1,068 세대',
    },
    {
      data : '84㎡', 
    },
    {
      data : '(주)동양건설산업'
    },
    {
      data : '주식회사 라인하우징',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '교통 입지',
      desc: '동탄 신도시와 세교1,2지구의 생활 인프라를 모두 누리는 세교지구 중심 더블 생활권'
    },
    {
      icon: Icon3Dsquare,
      title: '특화된 디자인',
      desc: '광폭거실, 남향위주 판상형 설계, 맞통풍 구조, 3면 발코니 설계, 스트리트형 단지 내 상가'
    },
    {
      icon: Icon3Dlife,
      title: '파라곤 브랜드 파워',
      desc: '전국 곳곳의 신도시마다 명품 주거문화를 선보여온 파라곤 브랜드 파워'
    },
    {
      icon: Icon3Dsecure,
      title: '안심 교육',
      desc: '도보 거리로 아이들이 안심하고 통학할 수 있는 초중고 학세권(예정)'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dUJ23/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/82017',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84',
            price: '-',
            competition: '-',
          },
          {
            size: '101',
            price: '-',
            competition: '-',
          },
          {
            size: '116',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '경기도 오산시 궐동 439'


}