import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";

export const DiscountPage = styled.div`
  padding : 128px 0 96px 0;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 96px 0 64px 0;
  }

`;

export const DiscountSection = styled.div`
  margin-top : 64px;
`;

export const DiscountCard = styled.div<{img : string}>`
  display : flex;

  width : 100%;
  aspect-ratio : 1022 / 560;

  border-radius : 20px;
  padding : 60px;
  display : flex;
  flex-direction : column;

  margin : auto;


  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align : flex-start;

  ${({img})=> img&& `background-image : url(${img});`}
  
  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding : 40px;
    aspect-ratio : 912 / 480;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 30px;
    aspect-ratio : 361 / 480;
  }


`;

export const CardIcon = styled.div`
  margin-top : auto;
  width: 130px;
  height : 30px;
  display : flex;
  object-fit : contain; 

  & img {
    width : 130px;
    height : 30px;
  }
`;