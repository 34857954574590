import { BannerJemulpoParkD, BannerJemulpoParkM, BannerJemulpoParkT, LogoJemulpoPark, NavJemulpoPark } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerJemulpoParkD,
    tablet : BannerJemulpoParkT,
    mobile : BannerJemulpoParkM,
  },
  logo : LogoJemulpoPark,
  nav : NavJemulpoPark,
  name: 'e편한세상 제물포역 파크메종',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',
  
  a : 37.4610199,
  b : 126.6578004,

  info : [
    {
      data : '인천광역시 미추홀구 숭의동 18-1'
    },
    {
      data : '총 736 세대',
    },
    {
      data : '39㎡, 59㎡A, 59㎡B, 74㎡A, 74㎡B, 84㎡', 
    },
    {
      data : '디엘건설 주식회사'
    },
    {
      data : '숭의3주택재개발정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '쾌속 교통 프리미엄',
      desc: '제물포역, 숭의역, 도화IC, 인천종합버스터미널 등 우수한 광역 교통망'
    },
    {
      icon: Icon3Dsquare,
      title: '생활권 프리미엄',
      desc: '단지 앞 수봉공원, 홈플러스, CGV, 미추홀구청, 인천보훈병원, 인하대 등 풍부한 인프라'
    },
    {
      icon: Icon3Dlife,
      title: '특화설계',
      desc: '실내 골프연습장, 피트니스, GX룸 등 특화 커뮤니티 시설'
    },
    {
      icon: Icon3Dsecure,
      title: '브랜드 라이프',
      desc: '브랜드 대상 11회 수상'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fbqa1/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '4억 2,520',
            competition: '발표 예정',
          },
          {
            size: '74A',
            price: '5억 3,730',
            competition: '발표 예정',
          },
          {
            size: '74B',
            price: '5억 2,140',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/87030',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59B',
            price: '4억 850',
            competition: '발표 예정',
          },
          {
            size: '74A',
            price: '5억 3,730',
            competition: '발표 예정',
          },
          {
            size: '84',
            price: '6억 1,990',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '인천광역시 미추홀구 숭의동 18-1'


}