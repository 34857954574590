import { BannerDaunFirstheimD, BannerDaunFirstheimM, BannerDaunFirstheimT, LogoDaunFirstheim, NavDaunFirstheim } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerDaunFirstheimD,
    tablet : BannerDaunFirstheimT,
    mobile : BannerDaunFirstheimM,
  },
  logo : LogoDaunFirstheim,
  nav : NavDaunFirstheim,
  name: '다운한양립스더퍼스트하임',
  likes : 1391,
  user: '418,914',
  count: '15,195,432',
  
  a : 35.5652103,
  b : 129.2655768,

  info : [
    {
      data : '울산광역시 중구 다운동 741-2'
    },
    {
      data : '총 400 세대',
    },
    {
      data : '59㎡A·B, 67, 84', 
    },
    {
      data : '한양건설'
    },
    {
      data : '울산다운지역주택조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '더 빨라질 교통',
      desc: '장검 IC, 울산 IC 5분, 울산역(KTX) 15분, 도시철도망 10분 이내 (트램 신복로터리역 예정)'
    },
    {
      icon: Icon3Dsquare,
      title: '더 편리한 생활',
      desc: '롯데, 현대 백화점 15분, 홈플러스, 롯데마트 15분, 울산대병원 25분'
    },
    {
      icon: Icon3Dlife,
      title: '더 에코 라이프',
      desc: '다전생태공원, 무궁화공원, 다정공원, 태화강'
    },
    {
      icon: Icon3Dsecure,
      title: '안심 학군',
      desc: '도보 등하교 가능한 안심통학권 (다운초, 다운중, 다전초, 다운고)'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fafe5/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/84916?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '울산광역시 중구 다운동 741-2'


}