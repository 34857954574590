import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";





export const RequestPage = styled.div`

    padding : 128px 0 64px 0;

    background: var(--primitive-blue-50, #EAF1FD);
    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding : 96px 0 64px 0;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 64px 0;
    }
`;

export const RequestButton = styled.div`
    padding : 14px;
    display : flex;
    flex-direction : row;
    gap : 8px;
    border-radius: 12px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--primitive-blue-600, #2A70F0);

    align-items : center;
    justify-content : center;

    height : 60px;
    width : 230px;
    margin : 32px auto auto auto;
    cursor:pointer;

    
`;

export const CountSection = styled.div`
    display : flex;
    flex-direction : row;
    gap :   32px;

    margin-top : 48px;
    
    justify-content : center;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
    }

`;


export const CountBox = styled.div`
    width : 495px;
    aspect-ratio : 495 / 132 ;
    padding : 24px;
    display : flex;
    flex-direction : column;
    border-radius: 20px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--background-primary-light, #FFF);

    
    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        aspect-ratio : 440 / 128 ;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding : 20px;
        width : 100%;
        height : fit-content;
        aspect-ratio : 361 / 104 ;
    }
`;

export const IconText = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : flex-start;
    gap : 8px;
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        gap : 4px;
    }
`;

export const Icon = styled.img`
    width : 24px;
    height : 24px;
`;

export const ThumbDiv = styled.div`
    width : 32px;
    height : 32px;
`

export const ThumbIcon = styled.img`
    width : 32px;
    height : 32px;
`