// InfoWindowTemplate.js 파일

const KakaoCSS = (path) => `
  <div style="padding: 8px; width: 120px;">
    ${path.name} <br>
    <a href='https://map.kakao.com/link/map/${path.name},${path.a},${path.b}' style="color: #2A70F0;" target="_blank">
      지도보기
    </a>
  </div>
`;

export default KakaoCSS;
