import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { StyledDisplay } from "@components/Text";
import styled from "@emotion/styled";


export const PriceBtnBox = styled.div`
  display : flex;
  flex-direction : row;
  gap : 8px;
  margin-top : 48px;
`;

export const PriceBtn = styled.div<{color : string}>`

  padding : 12px;
  display : flex;
  justify-content: center;
  align-items: center;
  border-radius : 8px;
  cursor : pointer;
  ${({color})=> color&& `background-color : ${color};`}
`;


export const PriceBoxSection = styled.div`
  margin-top : 32px;
  display : grid;
  grid-template-columns: repeat(3, 1fr);
  gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
    gap : 16px;
  }
`;

export const PriceBox = styled.div`
  display : flex;
  padding : 24px;
  flex-direction : column;
  border-radius: 16px;
  gap : 32px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
  background: var(--primitive-gray-50, #F8F8F8);
`;

export const DisplayLeftAuto = styled(StyledDisplay)`
  color : #2A70F0;
  margin-left : auto;
`;