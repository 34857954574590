import { OurPartnersLogo1, OurPartnersLogo1Mobile, OurPartnersLogo1Tablet, 
  OurPartnersLogo2, OurPartnersLogo2Mobile, OurPartnersLogo2Tablet, OurPartnersLogo3, 
  OurPartnersLogo3Mobile, OurPartnersLogo3Tablet, OurPartnersLogo4, OurPartnersLogo4Mobile, 
  OurPartnersLogo4Tablet, OurPartnersLogo5, OurPartnersLogo5Mobile, OurPartnersLogo5Tablet, } from "@assets/images/ourPartners";
import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, } from "@components/Text";

import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { ElevatorPage, LogoImg, LogoWrapper, StyledOurPartnersLogos } from "./Elevator.style";
import main from "@src/lang/ko/apartment/main";
import { SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";
const Elevator = ():React.ReactElement => {
  
  
  const [isMobile, setMobile] = useState<boolean>(false);
  
  useEffect (() => {

    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  })
  
  return (
    <ElevatorPage>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={main.elevator.title1} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <SpanBlue>
            <Trans i18nKey={main.elevator.titleBold} />
          </SpanBlue>
          <span>
            <Trans i18nKey={main.elevator.title2} />
          </span>
        </DisplayH2>


      {isMobile ? 
      <>
        <StyledOurPartnersLogos count={3}>
          { main.elevator.logos.map((logo, index) => (
            <>
              {index < 3 &&
              <LogoWrapper key={index}>
                <LogoImg src={logo.img} alt="logo image"/>
              </LogoWrapper>
              }
            </>
          ))}
        </StyledOurPartnersLogos>
          <StyledOurPartnersLogos count={2}>
            { main.elevator.logos.map((logo, index) => (
            <>
              {index > 2 &&
              <LogoWrapper key={index}>
                <LogoImg src={logo.img} alt="logo image"/>
              </LogoWrapper>
              }
            </>
            ))}
          </StyledOurPartnersLogos>
      </>
      :
        <StyledOurPartnersLogos count={3}>
          { main.elevator.logos.map((logo, index) => (
            <LogoWrapper key={index}>
              <LogoImg src={logo.img} alt="logo image"/>
            </LogoWrapper>
          ))}
        </StyledOurPartnersLogos>
      }
      </StyledGridContainer>
    </ElevatorPage>
  );
};

export default Elevator;