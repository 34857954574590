
import React, { useEffect, useState } from 'react';

import './NavBox.styl';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CommonState } from '@redux/reducers/commonReducer';

import { Trans, useTranslation } from 'react-i18next';

import { StyledLabel } from '@components/Text';
import { BannerUp, EventBanner } from '@assets/images/apartment';
import { NavBanner, NavBarLayout, NavImg, NavImgBox } from './NavBoxEvent.style';
import navEvent from '@src/lang/ko/apartment/navEvent';
import ModalEvent from '@pages/Apartment/ModalEvent/ModalEvent';
import { Spacing, Span00C37D, SpanFFF } from '@pages/Apartment/Apartment.style';
import { ImgCursor } from './NavBox.style';


const NavBoxEvent = () => {

  const [isGNBFixed, setIsGNBFixed] = useState(false);
  const [isBannerColor, setBannerColor] = useState(false);

  const checkRoot = () => {
    if (location.pathname === '/'
      || location.pathname === '/en/'
      || location.pathname === '/jp/'
      || location.pathname === '/cn/') {
      return true;
    }
    else {
      return false;
    }

  }

  const setColor = () => {
    if (checkRoot() && !isBannerColor) return true;
    else false;
  }


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const gnbHeight = 90; // GNB의 높이에 맞게 수정해주세요.

      if (scrollPosition > gnbHeight) {
        setIsGNBFixed(true);
        setBannerColor(true);
      } else {
        setIsGNBFixed(false);
        setBannerColor(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  // React
  const location = useLocation();
  

  // States
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  



  useEffect(() => {
    setIsOpenMenu(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {

    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isDark, setDark] = useState<boolean>(false);
  useEffect(() => {
    if (checkRoot()) {
      setDark(true);
    } else if (location.pathname.includes('enterprise') && !location.pathname.includes('techno')) {
      setDark(false);
    } else {
      setDark(false);
    }

  }, [location.pathname])


  useEffect(() => {
    setTreeHover(false);
    setNavHover(false);
    mouseLeaveCheck();
  }, [location.pathname])

  const [isMenuHover, setMenuHover] = useState<boolean>(false);

  const [onNavHover, setNavHover] = useState<boolean>(false);
  const [onTreeHover, setTreeHover] = useState<boolean>(false);
  const [onHeadHover, setHeadBox] = useState<boolean>(false);

  const mouseLeave = () => {
    setTreeHover(false);
    mouseLeaveCheck();
  }

  const mouseLeaveCheck = () => {
    if (!onNavHover && !onTreeHover && !onHeadHover) {
      setMenuHover(false);
    }
  }

  const [isModal, setModal] = useState<boolean>(false);
  const handleModal = () => {
    setModal(!isModal);
  }

  return (
    <>
      <NavBarLayout>
        <NavImg onClick={() => handleModal()}>
          <NavImgBox>
            <NavBanner src={BannerUp} alt='ORBRO Product Event Banner'/>
          </NavImgBox>
          <Spacing pixel={'8'}/>
          <StyledLabel size='small'>
            <SpanFFF>
              <Trans i18nKey={navEvent.title} />
            </SpanFFF>
            <Span00C37D>
              <Trans i18nKey={navEvent.bold} />
            </Span00C37D>
            <SpanFFF>
              <Trans i18nKey={navEvent.icon} />
            </SpanFFF>
          </StyledLabel>
        </NavImg>
      
      </NavBarLayout>

      {isModal &&
        <>
        <ModalEvent onClickToggleModal={handleModal}/>
        </>
      }

    </>
  );
};

export default NavBoxEvent;
