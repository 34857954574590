import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";



export const ExamLayout = styled.div`

  padding : 128px 0;
  background-color : #F8F8F8;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 96px 0;
  }

`;

export const StyledOurPartners = styled.div`
  width: 100%;
  padding: 128px 0 132px;
  background-color: ${({ theme }) => theme['BACKGROUND_SECONDARY']};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 96px 0;
  }
`

export const StyledOurPartnersHeading = styled(StyledHeading)`
  text-align: center;
`;

export const StyledOurPartnersDisplay = styled(StyledDisplay)`
  text-align: center;
  margin-top: 16px;
`;

export const StyledOurPartnersLogos = styled.div`
  margin-top: 64px;
  overflow: hidden;
  display : flex;
  flex-direction : row;
  gap : 32px;
  

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    margin-top: 64px;
  }
`;

export const StyledOurPartnersLogo = styled(StyledGridColumn)`
  display: inline-block;
  & img { 
    max-width: 100%;
    max-height: 60px;
    margin: 0 auto;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      max-height: 65px;
    }
  }
`;


const slideAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-548%);
  }
`;

export const ExamImgBox = styled.div`
  display : flex;
  width : 100%;
  flex-direction : column;
  align-items : center;
  justify-content : center;

  animation: ${slideAnimation} 25s linear infinite;
`;


export const ExamImg = styled.img`
  width : 340px; 
  height : 200px;
  border-radius : 8px;
  aspect-ratio : 390 / 200 ;
  object-fit : cover;
`;

export const ExamLogobox = styled.div`
  height : 30px;
  width: 90px;
  display : flex;
  margin-top : 16px;
  
  & img {
    object-fit : cover;
  }
`;

export const ExamLogo = styled.img`
  height : 30px;
  width : 80px;
`;
