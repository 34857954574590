import { BannerWeparkIlgokD, BannerWeparkIlgokM, BannerWeparkIlgokT, LogoWeparkIlgok, NavWeparkIlgok } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerWeparkIlgokD,
    tablet : BannerWeparkIlgokT,
    mobile : BannerWeparkIlgokM,
  },
  logo : LogoWeparkIlgok,
  nav : NavWeparkIlgok,
  name: '위파크 일곡공원',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',

  a : 36.3499148,
  b : 127.4404348,

  info : [
    {
      data : '광주광역시 북구 삼각동 700'
    },
    {
      data : '총 1,004 세대',
    },
    {
      data : '84㎡A·B·C, 138㎡A·B', 
    },
    {
      data : '라인건설,호반건설'
    },
    {
      data : '일곡공원개발(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '미래역세권',
      desc: '단지 약 300m 앞 광주 2호선 일곡역(가칭) 예정, 서광주IC, 양일로, 빛고을대로 등 쾌속 멀티교통'
    },
    {
      icon: Icon3Dsquare,
      title: '인프라 중신 생활권',
      desc: '대형마트, 행정복지센터, 일곡동 중심상업지구 등 편리한 생활 인프라를 모두 갖춘 원스톱 생활권'
    },
    {
      icon: Icon3Dlife,
      title: '힐링 숲세권',
      desc: '초대형 일곡공원을 앞마당처럼 누리는 1,004세대 프리미엄 대단지만의 청정 힐링라이프'
    },
    {
      icon: Icon3Dsecure,
      title: '안심 학세권',
      desc: '단지 옆 일동초, 일동중 등 12년 안심교육, 명품 학원가를 자랑하는 광주 3대 명문 일곡학군'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/grO22/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91763?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡B',
            price: '-',
            competition: '-',
          },
          {
            size: '138㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '광주광역시 북구 삼각동 700'


}