import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { TextCenterColumnFlex } from "@pages/Apartment/Banner/Banner.style";
import common from "@src/lang/ko/event/common";
import React, { useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { InfoGrid1 } from "../Info/Info.style";
import { MapPage, MapSize } from "./Map.style";
import { LabelColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import KakaoCSS from "./KakaoCSS";
import { DisplayH2 } from "../Banner/Banner.style";


import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";


const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}


declare global {
  interface Window {
    kakao: any;
  }
}




interface apart {
  apartName: string;
}

const Map: React.FC<apart> = ({ apartName }) => {

  const path = list[apartName];
  const data = {
    name : path.name,
    a : path.a,
    b : path.b
  }
  
  const mapContainer = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    if (mapContainer.current) {
      mapContainer.current.addEventListener('wheel', (e) => e.preventDefault(), { passive: false });
    }
  }, []);

  useEffect(() => {
    if (window.kakao && mapContainer.current) {
      const options: any = {
        center: new window.kakao.maps.LatLng(path.a, path.b),
        level: 3,
      };
      const map = new window.kakao.maps.Map(mapContainer.current, options); // 지도를 생성합니다

      
      // 마커가 표시될 위치입니다 
      const markerPosition  = new window.kakao.maps.LatLng(path.a, path.b); 

      // 마커를 생성합니다
      const marker = new window.kakao.maps.Marker({
          position: markerPosition
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);


      const iwContent = KakaoCSS(data);
      const iwPosition = new window.kakao.maps.LatLng(data.a, data.b); //인포윈도우 표시 위치입니다

      // 인포윈도우를 생성합니다
      const infowindow = new window.kakao.maps.InfoWindow({
      position : iwPosition, 
      content : iwContent 
      });

      // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
      infowindow.open(map, marker); 

      // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
      const mapTypeControl = new window.kakao.maps.MapTypeControl();
      map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT);

      const zoomControl = new window.kakao.maps.ZoomControl();
      map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
      
    }
  }, [apartName]);

  return (
    <MapPage>
      <StyledGridContainer>
        <TextCenterColumnFlex>
          <DisplayH2 size="small">
            <Trans i18nKey={`${apartName}.name`} />
            <SpanBlue>
              <Trans i18nKey={' ' + common.map} />
            </SpanBlue>
          </DisplayH2>
          <Spacing pixel="16"/>
          <LabelColor size="medium" color={"#818181"}>
            <Trans i18nKey={`${apartName}.map`} />
          </LabelColor>
        </TextCenterColumnFlex>
        <Spacing pixel="64"/>
          
        <StyledGridRow>
        <InfoGrid1 desktop={1} tablet={0} mobile={0}/>
          <StyledGridColumn desktop={10} tablet={8} mobile={4}>
            <MapSize ref={mapContainer}/>
          </StyledGridColumn>
        </StyledGridRow>
      </StyledGridContainer>
    </MapPage>
  )
}


export default Map;