import { StyledGridContainer } from "@components/Grid/Grid.style";
import apartList from "@src/lang/ko/event/apartList";
import React from "react";
import { InfoPage } from "./Common/Info/Info.style";
import { StyledHeading } from "@components/Text";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Spacing } from "@pages/Apartment/Apartment.style";



const RoutePage = () => {

    return (
    
      <InfoPage>
        <StyledGridContainer>

        {apartList.list.map((data, i) => (
          <>
            <div>
              <Link to={`/apart/${data.link}`} >
                <StyledHeading size="xsmall" >
                  <Trans i18nKey={data.name} />
                </StyledHeading>
              </Link>
              {data.name === ' ' && <Spacing pixel="24"/>}
            </div>
          </>))}

        </StyledGridContainer>
      </InfoPage>
    )

}


export default RoutePage;