import React from "react";
import { Helmet } from 'react-helmet';

const SEO = ({
    lang,
    helmetTitle,
    ogKey,
    ogDesc,
    ogImg,
    ogUrl,
  }: {
    lang: string;
    helmetTitle: string;
    ogKey: string;
    ogDesc: string;
    ogImg: string;
    ogUrl: string;
  }): React.ReactElement => {
  
  const jsonLd = {
    "@context": "http://schema.org",
    "@type": "BusinessEvent",
    "name": `${helmetTitle}`,
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "startDate": "2023-11-23T18:00:00",  
    "endDate": "2024-12-31T18:00:00",
    "location": { 
      "@type": "VirtualLocation",
      "url": "https://orbrohome.io"
    },
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "validFrom": "2023-11-23T18:00:00",
      "validThrough": "2024-12-31T18:00:00"
    },    
    "eventStatus": "https://schema.org/EventInProgress",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "provider": { 
      "@type": "Organization",
      "name": "orbro",
      "url": "http://orbrohome.io",
      "address": {
        "@type": "PostalAddress",
        "postalCode": "13561",
        "addressRegion": "경기",
        "addressLocality": "성남시 분당구",
        "streetAddress": "대왕판교로 660 B동 1203"
      },
    },
    "areaServed": {
      "@type": "Country",
      "name": "SouthKorea"
    },
    "organizer": {
      "@type": "Organization",
      "name": "orbro",
      "url": "http://orbrohome.io",
      "address": {
        "@type": "PostalAddress",
        "postalCode": "13561",
        "addressRegion": "경기",
        "addressLocality": "성남시 분당구",
        "streetAddress": "대왕판교로 660 B동 1203"
      },
    },
    "performer": {
      "@type": "PerformingGroup",
      "name": "Sales Team",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+8215229928",
        "contactType": "a sales contact point",
        "availableLanguage": "Korean"
      }
    },
    "parentOrganization": {
      "@type": "Organization",
      "@id": "http://orbrohome.io",
      "name": "orbro",
      "url": "http://orbrohome.io",
      "address": {
        "@type": "PostalAddress",
        "postalCode": "13561",
        "addressRegion": "경기",
        "addressLocality": "성남시 분당구",
        "streetAddress": "대왕판교로 660 B동 1203"
      }
    },
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean",
      "email": "orbro@orbro.io"
    }],
    "sameAs": [
      "https://orbrohome.io",
      "https://orbro.io",
      "https://plutocon.io",
      "https://www.kong-tech.com",
      "https://check.kong-tech.com",
      "https://blog.naver.com/orbro_platform",
      "https://home.orbro.io"
    ]
  }

  return (
    <>
      
      <Helmet>
        <html lang={lang}/>
        <title>{helmetTitle}</title>
        <meta name="description" content={ogDesc} />
        <meta name="keywords" content={ogKey} />
        <meta property="og:title" content={helmetTitle}/>
        <meta property="og:site_name" content={helmetTitle}/>
        <meta property="og:description" content={ogDesc} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImg} />
        <meta property="og:image:width" content= "1200"/>
        <meta property="og:image:height" content= "630"/>
        <meta property="og:locale" content={"ko_KR"} />
        
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={helmetTitle} />
        <meta name="twitter:description" content={ogDesc} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>

      </Helmet>
      
    </>
  )
};

export default SEO;