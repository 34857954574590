import {  BannerImuniparkD, BannerImuniparkM, BannerImuniparkT, LogoImunipark, NavImunipark } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerImuniparkD,
    tablet : BannerImuniparkT,
    mobile : BannerImuniparkM
  },
  logo : LogoImunipark,
  nav : NavImunipark,
  name: '이문 아이파크 자이',
  likes : 1243,
  user: '468,511',
  count: '15,445,432',

  a : 37.5984954,
  b : 127.0635351,

  info : [
    {
      data : '서울특별시 동대문구 이문동 149-8, 412-1번지 일대'
    },
    {
      data : '총 4,321 세대',
    },
    {
      data : '20㎡, 41㎡, 59㎡, 89㎡, 99㎡, 102㎡',
    },
    {
      data : 'HDC 현대산업개발(주) & GS 건설주식회사'
    },
    {
      data : '이문 3재정비 촉진구역 주택재개발 정비 사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '최적의 위치',
      desc: '더블 GTX-B/C(계획)로 교통중심이 될 청량리역과 가까운 외대앞역 및 강남과 광역권을 잇는 동부간선도로 및 내부순환도로의 쾌속교통망'
    },
    {
      icon: Icon3Dsquare,
      title: '브랜드 타운',
      desc: '도보권(이문초,석관중·고, 경희중·고)학군, 한국외대, 경희대, 코스트코, 롯데백화점, 삼육서울병원 등의 생활인프라와 천장산, 중랑천, 동부간선도로 상부 생태공원(계획) 등의 에코 인프라'
    },
    {
      icon: Icon3Dlife,
      title: '라이프 스타일',
      desc: '실시간으로 데이터를 누적하여 시간대별 경로를 분석 할 수 있습니다.'
    },
    {
      icon: Icon3Dsecure,
      title: '주거 가치',
      desc: '이문 아이파크 자이는 거주자의 편안한 생활을 위해 최고의 기술력을 바탕으로하여 시공 되었습니다.'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/ekr26/0',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '30A / 20A',
            price: '3억 8510',
            competition: '6.6 : 1',
          },
          {
            size: '30A / 20A',
            price: '5억 8,909',
            competition: '106.6 : 1',
          },
          {
            size: '82A / 59A',
            price: '9억 3,571',
            competition: '18.5 ; 1',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/79286?checking_subscription=true',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '30A / 20A',
            price: '3억 6510',
            competition: '5.6 : 1',
          },
          {
            size: '30A / 20A',
            price: '5억 3,909',
            competition: '101.6 : 1',
          },
          {
            size: '82A / 59A',
            price: '10억 1,001',
            competition: '15.5 ; 1',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/?#SYDetail?build_dtl_cd=6027231&supp_cd=9040141&SYMap=37.5997579,127.0617221,17&a=IA01',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '30A / 20A',
            price: '4억 3010',
            competition: '8.6 : 1',
          },
          {
            size: '30A / 20A',
            price: '5억 3,941',
            competition: '113.6 : 1',
          },
          {
            size: '82A / 59A',
            price: '9억 7,211',
            competition: '20.5 ; 1',
          },
        ]
      },

    ]

  },

  map: '서울시 동대문구 이문동 257-42'


}