import { BannerWonbongHildesheimD, BannerWonbongHildesheimM, BannerWonbongHildesheimT, LogoWonbongHildesheim, NavWonbongHildesheim } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerWonbongHildesheimD,
    tablet : BannerWonbongHildesheimT,
    mobile : BannerWonbongHildesheimM
  },
  logo : LogoWonbongHildesheim,
  nav : NavWonbongHildesheim,
  name: '운암자이포레나 퍼스티체',
  likes : 1589,
  user: '468,511',
  count: '15,445,432',
  
  a : 35.1820541,
  b : 126.8766851,

  info : [
    {
      data : '광주광역시 북구 운암동 252'
    },
    {
      data : '총 3,214 세대',
    },
    {
      data : '59㎡A, 59㎡B, 75㎡A, 75㎡B, 84㎡A, 84㎡B, 84㎡C, 109㎡', 
    },
    {
      data : 'GS건설(주), 한화/건설, HDC현대산업개발'
    },
    {
      data : '운암3단지 재건축정비사업조합',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '특화 설계',
      desc: '채광과 일조를 고려한 남향 위주 단지 배치'
    },
    {
      icon: Icon3Dsquare,
      title: '다채로운 커뮤니티',
      desc: '생활에 여유와 활력을 더해주는 고품격 커뮤니티'
    },
    {
      icon: Icon3Dlife,
      title: '에코 라이프',
      desc: '지상에 차가 없는 공원형 단지 운암산, 중외공원 등 힐링 자연환경'
    },
    {
      icon: Icon3Dsecure,
      title: '안심 교육환경',
      desc: '단지 내 어린이집 및 유치원(예정) 단지 앞 초등학교 등'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/emM22/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '75㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/85309',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '59㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '75㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '75㎡A',
            price: '-',
            competition: '-',
          },
          {
            size: '84㎡A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '광주광역시 북구 운암동 252'


}