import { BannerBusanDetreD, BannerBusanDetreM, BannerBusanDetreT, BannerWonbongHildesheimD, BannerWonbongHildesheimM, BannerWonbongHildesheimT, LogoBusanDetre, LogoWonbongHildesheim, NavBusanDetre, NavWonbongHildesheim } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerWonbongHildesheimD,
    tablet : BannerWonbongHildesheimT,
    mobile : BannerWonbongHildesheimM
  },
  logo : LogoWonbongHildesheim,
  nav : NavWonbongHildesheim,
  name: '원봉 힐데스하임',
  likes : 1589,
  user: '468,511',
  count: '15,445,432',
  
  a : 36.6080352,
  b : 127.5160802,

  info : [
    {
      data : '충청북도 청주시 상당구 용암동 산222'
    },
    {
      data : '총 1,211 세대',
    },
    {
      data : '84 ~ 273㎡', 
    },
    {
      data : '(주)원건설'
    },
    {
      data : '(주)정각',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '빠른 교통',
      desc: '청주 제 1,2, 순환로를 통해 청주 주요 거점, 외곽으로 이동이 용이한 사통팔달 교통망'
    },
    {
      icon: Icon3Dsquare,
      title: '원하던 도심생활',
      desc: '동남택지지구 최종심 입지에서 2개의 중심상업시설 인프라를 누리는 편리한 생활'
    },
    {
      icon: Icon3Dlife,
      title: '기다린 푸른공원',
      desc: '기다리던 입지에서 원봉공원의 자연을 가장 쾌적하게 누리는 에코라이프'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 도보학군',
      desc: '단지 앞 초롱꽃유치원 및 운동초, 운동중, 신설초, 신설고 예정의 우수한 교육환경'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/elY9a/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/82264',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '충청북도 청주시 상당구 용암동 산222'


}