import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";

import React from "react";
import { Trans } from "react-i18next";

import { StyledOurPartnersLogo, StyledOurPartnersLogos } from "./OurPartners.style";
import main from "@src/lang/ko/apartment/main";
import { BorderBottomLine, Layout128to96, ParagraphH1, ParagraphH2, Spacing, SpanBlue } from "../Apartment.style";


const OurPartners = ():React.ReactElement => {
  
  return (
    <Layout128to96>
      <StyledGridContainer>

      <StyledDisplay size="small">
          <Trans i18nKey={`${main.partners.title1}`} />
        </StyledDisplay>
        <StyledDisplay size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.partners.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.partners.title2}`} />
          </span>
        </StyledDisplay>
      
        <Spacing pixel="16"/>
        <ParagraphH2 size="large">
          <Trans i18nKey={`${main.partners.desc}`} />
          {<br/>}
          <Trans i18nKey={`${main.partners.desc2}`} />
        </ParagraphH2>


        <StyledOurPartnersLogos>
          { main.partners.logos.map((logo, index) => (
            <StyledOurPartnersLogo key={`our-partners-${index}`}> 
              <img src={logo.img} alt="orbro partnership apartments"/>
            </StyledOurPartnersLogo>
          ))}
        </StyledOurPartnersLogos>
        
        <Spacing pixel="96"/>
        <BorderBottomLine/>
        
      </StyledGridContainer>
    </Layout128to96>
  );
};

export default OurPartners;