import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";



export const CustomerPage = styled.div`

  padding : 128px 0 96px 0;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    padding : 96px 0 64px 0;
  }
`;

export const PriceBtnBox = styled.div`
  display : flex;
  flex-direction : row;
  gap : 8px;
  margin-top : 48px;
`;

export const PriceBtn = styled.div`

  padding : 12px;
  display : flex;
  justify-content: center;
  align-items: center;
`;


export const PriceBoxSection = styled.div`
  margin-top : 32px;
  display : grid;
  grid-template-columns: repeat(3, 1fr);
  gap : 32px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
    gap : 16px;
  }
`;

export const CustomerBox = styled.div<{type : string}>`
  display : flex;
  padding : 32px;
  flex-direction : column;
  max-height : 180px;
  border-radius: 16px;
  gap : 8px;
  border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
  background: var(--primitive-gray-50, #F8F8F8);

  ${({type}) => type !== '' ? 'cursor:pointer;' : 'cursor:text;'}

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    padding : 24px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    gap : 4px;
    padding : 20px;
  }
`;



export const IconBox = styled.div`
  width : 32px;
  height : 32px;
  display : flex;
  object-fit : contain;

`;


export const DescBox = styled.div`

display : flex;
width : 100%;
justfiy-content : space-between;

`;

export const ArrowIcon = styled.img`
  width: 32px;
  height : 32px;
  display : flex;
  object-fit : contain;

  margin-left : auto;
`;