import { 
  AccessProduct1Desktop,
  AccessProduct1Mobile,
  AccessProduct1Tablet,
  AccessProduct2Desktop,
  AccessProduct2Mobile,
  AccessProduct2Tablet,
  AccessProduct3Desktop,
  AccessProduct3Mobile,
  AccessProduct3Tablet,
  AccessProduct4Desktop,
  AccessProduct4Mobile,
  AccessProduct4Tablet,
  ApartBanner, ElevatorPartner1, ElevatorPartner2, ElevatorPartner3, ElevatorPartner4, 
  ElevatorPartner5, ExamLogo1, ExamLogo2, ExamLogo3, ExamLogo4, ExamLogo5, IconAppStore, IconGooglePlay, 
  ImgCloudDesktop, 
  ImgCloudMobile, 
  ImgCloudTablet, 
  ImgServerDesktop, 
  ImgServerMobile, 
  ImgServerTablet, 
  Installation1, 
  Installation2, 
  Installation3, 
  Installation4, 
  Installation5, 
  OnepassImg1desktop, 
  OnepassImg1mobile, 
  OnepassImg1tablet, 
  OnepassImg2desktop, 
  OnepassImg2mobile, 
  OnepassImg2tablet, 
  OnepassImg3desktop, 
  OnepassImg3mobile, 
  OnepassImg3tablet, 
  OnepassImg4desktop, 
  OnepassImg4mobile, 
  OnepassImg4tablet, 
  OnepassImg5desktop, 
  OnepassImg5mobile, 
  OnepassImg5tablet, 
  OnepassImg6desktop, 
  OnepassImg6mobile, 
  OnepassImg6tablet, 
  OrbroHomeService1, 
  OrbroHomeService2, 
  OrbroHomeService3, 
  OrbroHomeService4, 
  OrbroManagerFunction1, 
  OrbroManagerFunction2, 
  OrbroManagerFunction3, 
  OrbroManagerFunction4, 
  OrbroPartner1, OrbroPartner10, OrbroPartner11, OrbroPartner12, OrbroPartner13, 
  OrbroPartner14, OrbroPartner15, OrbroPartner16, OrbroPartner17, OrbroPartner18, OrbroPartner19, 
  OrbroPartner2, OrbroPartner20, OrbroPartner21, OrbroPartner22, OrbroPartner23, OrbroPartner24, 
  OrbroPartner3, OrbroPartner4, OrbroPartner5, OrbroPartner6, OrbroPartner7, 
  OrbroPartner8, OrbroPartner9, OrbroService1desktop, 
  OrbroService1mobile, OrbroService1tablet, OrbroService2desktop,
  OrbroService2mobile, OrbroService2tablet, OrbroService3desktop,
  OrbroService3mobile, OrbroService3tablet, OrbroService4desktop, 
  OrbroService4mobile, OrbroService4tablet, OrbroService5desktop, 
  OrbroService5mobile, OrbroService5tablet, redDotLogo } from "@assets/images/apartment";
import { IconChat, IconEmail, IconMobile } from "@assets/images/apartment/icon";


export default {

  newTag : '출시 예정',

  banner : {
    title : '아파트 원패스',
    color : '오브로 홈',
    titleTail : '으로 시작하세요',
    desc : '오브로 홈은 국내에서 가장 혁신적이고 안정적인 아파트 원패스 시스템 입니다.',

    download : '제안서 다운로드',
    request : '서비스 도입 문의',

    img : ApartBanner
  },

  partners : {

    title1 : '국내 1위, 최다 설치',
    titleBold : '500,000+ ',
    title2 : '입주민이 사용하고 있습니다',

    desc : '오브로 홈은 공동현관 비밀번호, 주차 관제 시스템 등 아파트 단지를 더 편리하고 높은 수준으로 관리하는 디지털 원패스 시스템입니다.',
    desc2 : '우수한 서비스 품질, 국내 최다 설치량, 다양한 서비스와의 연동을 통해 새로운 차원의 아파트 생활을 경험하세요.',

    logos : [
      {img : OrbroPartner1},
      {img : OrbroPartner2},
      {img : OrbroPartner3},
      {img : OrbroPartner4},
      {img : OrbroPartner5},
      {img : OrbroPartner6},
      {img : OrbroPartner7},
      {img : OrbroPartner8},
      {img : OrbroPartner9},
      {img : OrbroPartner10},
      {img : OrbroPartner11},
      {img : OrbroPartner12},
      {img : OrbroPartner13},
      {img : OrbroPartner14},
      {img : OrbroPartner15},
      {img : OrbroPartner16},
      {img : OrbroPartner17},
      {img : OrbroPartner18},
      {img : OrbroPartner19},
      {img : OrbroPartner20},
      {img : OrbroPartner21},
      {img : OrbroPartner22},
      {img : OrbroPartner23},
      {img : OrbroPartner24},
    ]

  },

  onepass : {
    title1 : '모바일 출입과 다양한 서비스',
    title2 : '',
    titleBold : '오브로 홈',
    title3 : '을 선택할 이유 입니다',

    box : [
      {
        tag : '모바일 자동 출입',
        title : '앱을 켜지 않아도<br/>자동으로 출입',
        img : {
          desktop : OnepassImg1desktop,
          tablet : OnepassImg1tablet,
          mobile : OnepassImg1mobile
        },
        height : {
          desktop : 540,
          tablet : 540,
          mobile : 420
        }
      },
      {
        tag : '개인정보 보호',
        title : '완벽한 방법으로<br/>입주민 개인정보 보호',
        img : {
          desktop : OnepassImg2desktop,
          tablet : OnepassImg2tablet,
          mobile : OnepassImg2mobile
        },
        height : {
          desktop : 420,
          tablet : 420,
          mobile : 420
        }
      },
      {
        tag : '위젯 지원',
        title : '편리한 사용성을 위한<br/>다양한 위젯 지원',
        img : {
          desktop : OnepassImg3desktop,
          tablet : OnepassImg3tablet,
          mobile : OnepassImg3mobile
        },
        height : {
          desktop : 420,
          tablet : 440,
          mobile : 420
        }
      },
      {
        tag : '엘리베이터 호출',
        title : '문이 열리면,<br/>엘리베이터 자동 호출',
        img : {
          desktop : OnepassImg4desktop,
          tablet : OnepassImg4tablet,
          mobile : OnepassImg4mobile
        },
        height : {
          desktop : 540,
          tablet : 620,
          mobile : 420
        }
      },
      {
        tag : '세계 3대 디자인 어워드',
        title : '세계에서 인정받은<br/>제품 디자인',
        img : {
          desktop : OnepassImg5desktop,
          tablet : OnepassImg5tablet,
          mobile : OnepassImg5mobile
        },
        height : {
          desktop : 540,
          tablet : 540,
          mobile : 420
        }
      },
      {
        tag : '지도 기반의 서비스',
        title : '아파트 단지를 한눈에<br/>CCTV, 미세먼지 측정',
        img : {
          desktop : OnepassImg6desktop,
          tablet : OnepassImg6tablet,
          mobile : OnepassImg6mobile
        },
        height : {
          desktop : 420,
          tablet : 440,
          mobile : 420
        }
      }
    ]
  },


  service : {
    left : {
      title1 : '독보적인 레퍼런스와',
      titleBold : '기본에 충실한 ',
      title2 : '서비스',
      desc : '오브로 홈은 모바일 출입시장에서 가장 오래된 경험과 판매량을 보유한 서비스 입니다.<br/>견고한 기본 기능에서 시작해 압도적으로 차별화 된 서비스 품질을 경험해 보세요.',

      fall : [
        {
          title : '자동 출입 설정',
          desc : '앱을 켜지 않아도 출입문이 열리는 자동 출입 기능을 지원합니다.',
          img : OrbroHomeService1
        },
        {
          title : '모바일 위젯 설정',
          desc : '빠르게 오브로 앱 서비스에 접근할 수 있는 모바일 위젯을 지원 합니다.',
          img : OrbroHomeService2
        },
        {
          title : '출입 거리 설정',
          desc : '문이 열리는 적정 거리를 모바일에서 설정할 수 있습니다.',
          img : OrbroHomeService3
        },
        {
          title : '엘리베이터 자동 호출',
          desc : '출입문이 열리면 자동으로 엘리베이터를 호출합니다.',
          img : OrbroHomeService4
        },
      ]
    },

    rightImg : ''
  },

  elevator : {
    title1 : '더 편리한 출입을 위해',
    titleBold : '모든 엘리베이터와의 ',
    title2 : '연동을 지원합니다',
    logos: [
      {img : ElevatorPartner1},
      {img : ElevatorPartner2},
      {img : ElevatorPartner3},
      {img : ElevatorPartner4},
      {img : ElevatorPartner5},
    ]

  },

  secure : {
    title1 : '가장 안전한 방법으로,',
    title2 : '입주민의',
    titleBold : ' 개인정보 보호',
    desc: "입주민들의 개인정보와 출입 데이터가 무분별하게 수집되고 있는 문제를 해결하기 위해,<br/>오브로 홈은 <strong>강화된 보안 체계</strong>를 갖춘 전용 서버를 아파트에 설치하여, 입주민의 개인정보와 출입기록을 보호하고 있습니다.",
    box : {
      left : {
        tag : '타회사',
        titleBold1 : '낮은 수준의 ',
        title1 : '보안등급 체계',
        title2 : '클라우드로',
        titleBold2 : ' 개인정보와 출입정보 업로드',
        img :  {
          desktop : ImgCloudDesktop,
          tablet : ImgCloudTablet,
          mobile : ImgCloudMobile
        }
      },
      right : {
        tag : '오브로 홈',
        title1 : '대기업에서 검증한 ',
        titleBold1 : '최고 등급의 보안체계',
        title2 : '구축형 서버로 모든 개인정보를 ',
        titleBold2 : '아파트에서 보관',
        img : {
          desktop : ImgServerDesktop,
          tablet : ImgServerTablet,
          mobile : ImgServerMobile
        }
      }
    }
    
  },



  price: {

    title : '오브로 홈의 가격정책은',
    titleBold : '가장 합리적',
    title2 :'입니다',

    desc1 : '한번 구축하고 ',
    descBold1 :'영원히 무료 ',
    desc2 : '사용하세요.',

    desc3 : '초기비용 부담없이 ',
    descBold2 :'매월 적은 비용',
    desc4 : '으로 서비스를 시작하세요.',
    btn : [
      {
        title : '구축형 서비스'
      },
      {
        title : '월간 서비스'
      }
    ],
    box: [
      [
        {
          title1: '매달 발생하는',
          title2 : '세대당 ',
          titleBold : '서비스 비용',
          price: '무료'
        },
        {
          title1: '엘리베이터 호출, 위젯 등',
          title2 : '',
          titleBold : '앱 부가 서비스',
          price: '무료'
        },
        {
          title1: '구축형 설치',
          title2 : '',
          titleBold : '업계 최저가격 보장',
          price: '최저 가격'
        },
      ],
      [
        {
          title1: '도입시 납부해야 하는',
          title2 : '',
          titleBold : '부담스러운 구축 비용',
          price: '무료'
        },
        {
          title1: '엘리베이터 호출, 위젯 등',
          title2 : '',
          titleBold : '앱 부가 서비스',
          price: '무료'
        },
        {
          title1: '세대당 비용지불',
          title2 : '',
          titleBold : '업계 최저가격 보장',
          price: '300원~'
        },
      ],

    ],


  },

  manager : {
    title1 : '누구나 쉽게,',
    title2 : '관리자 기능',
    titleBold : '교육이 필요없는 ',

    desc1 : "아파트 입주민 목록을 쉽게 관리하고, ",
    desc2 : "도 가능합니다.",
    descBold : "문자를 통한 초대",
    

    btn : [
      {text : '입주민 초대', desc1:'아파트 입주민 목록을 쉽게 관리하고, ', descBold:'문자를 통한 초대', desc2:'도 가능합니다.', img : OrbroManagerFunction1},
      {text : '출입 권한 관리', desc1:'아파트 출입 권한 목록을 쉽게 관리하고, ', descBold:'여러개의 기기를 권한에 등록 ', desc2:'할 수 있습니다.', desc:'', img : OrbroManagerFunction2},
      {text : '그룹관리', desc1:'입주 세대별 ', descBold:'그룹 관리', desc2:'가 가능합니다.', img : OrbroManagerFunction3},
      {text : '입주민 관리', desc1:'입주민 ', descBold:'삭제 및 수정', desc2:'이 쉽게 가능합니다.', img : OrbroManagerFunction4},
    ]
  },


  upgrade : {
    title1 : '최상위 서비스를 위해',
    title2 : '',
    titleBold : '특별히 설계된 ',
    title3 : '제품',


    desc1 : '오브로 홈의 ',
    descBold1 : '모든 제품은 국내 최고 수준',
    desc2 : '의 하드웨어 엔지니어들과 제품 디자이너들에 의해 ',
    dsecBold2 :'자체적으로 설계 ',
    desc3 : '되었습니다.',
    

    
    reddot : redDotLogo,
    img : [
      {
        tag : '디자인',
        title : '세게 3대 디자인 상<br/>레드닷 수상',
        img : {
          desktop : AccessProduct1Desktop,
          tablet : AccessProduct1Tablet,
          mobile : AccessProduct1Mobile
        }
      },
      {
        tag : '각도 조절',
        title : '유연한 각도 조절로<br/>모든 공간 설치 가능',
        img : {
          desktop : AccessProduct2Desktop,
          tablet : AccessProduct2Tablet,
          mobile : AccessProduct2Mobile
        }
      },
      {
        tag : '고성능 센서',
        title : '고성능 레이더 센서로<br/>정확한 사람인식',
        img : {
          desktop : AccessProduct3Desktop,
          tablet : AccessProduct3Tablet,
          mobile : AccessProduct3Mobile
        }
      },
      {
        tag : '고감도 안테나',
        title : '최고등급 블루투스<br/>독보적인 지향성 안테나',
        img : {
          desktop : AccessProduct4Desktop,
          tablet : AccessProduct4Tablet,
          mobile : AccessProduct4Mobile
        }
      },
    ]
  },


  oneService : {
    title1 : '아파트에 필요한 모든 것,',
    titleBold : '하나의 앱 서비스로 ',
    title2 : '사용하세요.',
    boxUp : 
    [
      {tag : '출입 단말기', title : '공동현관<br/>원패스 출입 단말기', img : {
        desktop : OrbroService1desktop,
        tablet : OrbroService1tablet,
        mobile : OrbroService1mobile
      }, new : '0'},
      {tag : '오브로 홈 서버', title : '관리자 소프트웨어가 내장된<br/>구축형 서버', img : {
        desktop : OrbroService2desktop,
        tablet : OrbroService2tablet,
        mobile : OrbroService2mobile
      }, new : '0'},
      {tag : '미세먼지 센서', title : '아파트 미세먼지<br/>지도에서 한번에 확인', img : {
        desktop : OrbroService3desktop,
        tablet : OrbroService3tablet,
        mobile : OrbroService3mobile
      }, new : '1'},
      {tag : 'CCTV 카메라', title : '놀이터, 야외 주차장 등<br/>CCTV 영상 보기', img : {
        desktop : OrbroService4desktop,
        tablet : OrbroService4tablet,
        mobile : OrbroService4mobile
      }, new : '0'},
      {tag : '비상벨 제품', title : '안전을 위한 비상벨<br/>여러 방법으로 활용', img : {
        desktop : OrbroService5desktop,
        tablet : OrbroService5tablet,
        mobile : OrbroService5mobile
      }, new : '1'}
    ]
  },

  exam : {
    title1 : '한국 아파트의 대부분은',
    titleBold : '오브로 홈',
    title2 : '을 선택하고 있습니다.',

    lineup : [
      {
        img : Installation1,
        logo : ExamLogo1,
        name : '서울숲리버뷰자이',
        desc : '1,034세대/ 7동',
      },
      {
        img : Installation2,
        logo : ExamLogo2,
        name : '래미안길음센터피스',
        desc : '2,352세대/ 24동',
      },
      {
        img : Installation3,
        logo : ExamLogo3,
        name : '송도 SK뷰',
        desc : '2,100세대/ 11동',
      },
      {
        img : Installation4,
        logo : ExamLogo4,
        name : 'DMC 센트럴 아이파크',
        desc : '1,061세대/ 13동',
      },
      {
        img : Installation5,
        logo : ExamLogo5,
        name : '수원 호매실 호반 베르디움 2차',
        desc : '1,100세대/ 13동',
      }
    ]
  },

  customer : {
    titleBold : '365일 ',
    title1 : '고객 센터에 ',
    title2 : '무엇이든 물어보세요.', 
    box : [
      {
        icon : IconEmail,
        title : '이메일 문의',
        desc : 'pr@orbro.io',
        link :'',
      },
      {
        icon : IconMobile,
        title : '전화상담',
        desc : '1522-9928',
        link : ''
      },
      {
        icon : IconChat,
        title : '채팅 상담',
        desc : '채널톡 문의',
        link : 'https://open.kakao.com/o/sGR51nTf'
      },
    ]
  }

}