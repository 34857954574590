import styled from "@emotion/styled";

export const BottomFix = styled.div`
    
position : fixed;
    width : 100%;
    display : flex;
    margin-top : auto;
    bottom : 28px;
    z-index : 10;

    margin : 0 auto;
    padding : 0 16px;

    & * {
        font-family : Pretendard !important;
    }
`;


//margin-bottom : 24px; + 52px + 28
export const BottomMenuSection = styled.div`

    display : flex;
    width : 100%;
    justify-content : center;
    align-items : center;
    flex-direction : row;
    gap : 8px;
    z-index : 11;
    
`;



export const BottomMenuBtn = styled.div<{color : string}>`
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    z-index : 12; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a drop shadow with rgba values for transparency */

    text-align : center;
    cursor:pointer;

    ${({color}) => color && `background-color : ${color}`}
`;
