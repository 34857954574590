import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import React from "react";
import { Trans } from "react-i18next";
import { ArrowIcon, CustomerBox, CustomerPage, DescBox, IconBox, PriceBoxSection, PriceBtn, PriceBtnBox } from "./Customer.style";
import main from "@src/lang/ko/apartment/main";
import { BlueArrowRight } from "@assets/images/apartment/icon";
import { HeadingColor, Img32, Layout128to96, SpanBlue } from "../Apartment.style";
import { Link } from "react-router-dom";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const Customer = () => {

  return (
    <CustomerPage>
      <StyledGridContainer >

        <StyledDisplay size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.customer.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.customer.title1}`} />
          </span>
        </StyledDisplay>
        <DisplayH2 size="small">
          <Trans i18nKey={`${main.customer.title2}`} />
        </DisplayH2>

        <PriceBoxSection>
          {main.customer.box.map((data, i) => (
            <>
              {data.link !== '' ?
                <a href={`${data.link}`} target="_balnk">
                  <CustomerBox type={data.link}>
                    <IconBox>
                      <Img32 src={data.icon} alt="icon" />
                    </IconBox>
                    <HeadingColor size="xxxsmall" color={"#818181"}>
                      <Trans i18nKey={`${data.title}`} />
                    </HeadingColor>
                    <DescBox>
                      <HeadingColor size="small" color={"#2A70F0"}>
                        <Trans i18nKey={`${data.desc}`} />
                      </HeadingColor>
                      {data.link && <ArrowIcon src={BlueArrowRight} alt="blue arrow icon" />}
                    </DescBox>

                  </CustomerBox>
                </a>
                :
                <CustomerBox type={data.link}>
                  <IconBox>
                    <Img32 src={data.icon} alt="icon" />
                  </IconBox>
                  <HeadingColor size="xxxsmall" color={"#818181"}>
                    <Trans i18nKey={`${data.title}`} />
                  </HeadingColor>
                  <DescBox>
                    <HeadingColor size="small" color={"#2A70F0"}>
                      <Trans i18nKey={`${data.desc}`} />
                    </HeadingColor>
                    {data.link && <ArrowIcon src={BlueArrowRight} alt="blue arrow icon" />}
                  </DescBox>
                </CustomerBox>}

            </>
          ))}

        </PriceBoxSection>

      </StyledGridContainer>
    </CustomerPage>

  )
}


export default Customer;