import { BannerUamHarringtonD, BannerUamHarringtonM, BannerUamHarringtonT, LogoUamHarrington, NavUamHarrington } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerUamHarringtonD,
    tablet : BannerUamHarringtonT,
    mobile : BannerUamHarringtonM
  },
  logo : LogoUamHarrington,
  nav : NavUamHarrington,
  name: '우암 해링턴마레',
  likes : 1911,
  user: '468,511',
  count: '15,445,432',

  a : 35.128753,
  b : 129.070612,

  info : [
    {
      data : '남구 우암동 198-3번지 일원'
    },
    {
      data : '총 2,205 세대',
    },
    {
      data : '59㎡, 73㎡, 84㎡', 
    },
    {
      data : '효성중공업(주), 진흥기업(주)'
    },
    {
      data : '우암1구역주택재개발정비사업',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '입지여건',
      desc: '엑스포 예정부지이며 우암부두 해양클러스터 산업과 연결되어있으며 미55보급창 공원화까지 조성될 예정으로, 호재를 품었습니다.'
    },
    {
      icon: Icon3Dsquare,
      title: '부산 최초 트리플 스카이 브릿지',
      desc: '부산광역시 최초 트리플 스카이 브릿지 설계로 부산광역시 남구에서 상징성 있는 아파트로 거듭날것으로 예상됩니다.'
    },
    {
      icon: Icon3Dlife,
      title: '랜드스케이프 적용단지',
      desc: '하늘과 바다를 즐기며 조망할 수 있는 공간입니다. 해운대, 광안리,우암동도 오션뷰의 가치를 품었습니다.'
    },
    {
      icon: Icon3Dsecure,
      title: '안전한 교육환경',
      desc: '성천초등학교가 단지 앞에 위치한 초품아 단지로 저학년 아이들도 안심하고 통학할 수 있어 좋습니다. 그리고 대연중, 성동중, 성지고, 배정고 (1km) 등이 주변에 위치합니다.'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/gq63e/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '5억 6,400',
            competition: '-',
          },
          {
            size: '84A',
            price: '7억 9,400',
            competition: '-',
          },
          {
            size: '84C',
            price: '7억 5,000',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91558',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '83A/59A',
            price: '5억 6,400',
            competition: '발표 예정',
          },
          {
            size: '116A/84A',
            price: '7억 9,400',
            competition: '발표 예정',
          },
          {
            size: '129T/84T',
            price: '8억 5,600',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6027261&supp_cd=9040228&DetailType=Complex&SYMap=35.128753,129.070612,16&a=&b=C12',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '5억 6,400',
            competition: '-',
          },
          {
            size: '84A',
            price: '7억 9,400',
            competition: '-',
          },
          {
            size: '84C',
            price: '7억 5,000',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '남구 우암동 198-3번지 일원'


}