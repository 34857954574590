
import styled from "@emotion/styled";
import React from "react";

import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledParagraph } from "@components/Text";



export const ModalContainer = styled.div`
 
  width : 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
    height : 100%;
    text-align : center;
  }

`;

export const ModalTitleBox = styled.div`
  display : flex;
  flex-direction : column;
  margin-top : 32px;
  text-align : center;
`;

export const InputGrid = styled.div`
  display : grid;
  grid-template-columns : repeat(2,1fr);
  gap : 16px;
`;


export const DialogBox = styled.dialog`
  width: 630px;
  height : fit-content;
  padding : 24px;
  display: flex;
  
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
  box-sizing: border-box;
  background-color: white;
  z-index: 10000;

  margin: 0 auto;
  

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    
    width : 100%;
    height : 100%;
    display : flex;
    flex-direction : column;
    text-align : start;
    top: 296px;
  }


`;



export const DialogEmailBox = styled.div`


  width : 100%;
  height: 56px;
  border : 1px solid rgba(0, 0, 0, 0.08);
  border-radius : 8px;

  color : #BDBDBD;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : 100%;
  }
  

`;


export const DialogEmail = styled.input`
    border : none;
    border-radius : 8px;
    height : 100%;
    width : 100%;
    padding-left : 12px;

    ::placeholder {
      color: #BDBDBD; /* 여기에 원하는 색상을 지정합니다 */
    }
    
`;


export const DialogPolicyBox = styled.div`

    display : flex;
    width : 100%;
    gap : 2px;
    width : 100%;
    text-align : center;

`;

export const DialogBtnBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 8px;
    margin-left : auto;
    width : fit-content;
    height : 44px;
    

`;

export const DialogBtnCancel = styled.div`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  border : none;
  padding : 12px 24px;
  cursor: pointer;

  background-color : #FFFFFF;
  display : flex;

  justify-content : center;
  align-items : center;

`;
export const DialogBtn = styled.div`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  border : none;
  padding : 12px 24px;
  cursor: pointer;

  background-color : black;
  display : flex;

  justify-content : center;
  align-items : center;

`;


export const DialogBorder = styled.div`

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    width : calc(100% + 48px);
    margin : 0 -24px;
    border-bottom : 1px solid rgba(0,0,0,0.08);
    padding-bottom : 16px;
    margin-bottom : 24px;
  }
`


export const DialogDescBox = styled.div`



`;

export const DialogSpanText = styled.span`

  color : #646464;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;


`;

export const PolicySpanText = styled.span`
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.25);
`;





export const InputBox = styled.div`
  margin-top : 56px;
  width: 100%;

  display : flex;
  flex-direction : column;
`;




export const StyledUnderline = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  background-color: #2A70F0;
`;


export const StyledDownloadPolicyLink = styled.a`
  position: relative; 
  display: inline-block;
`;


export const DialogInputText = styled.div<{inputValue : boolean}>`
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  width : fit-content;
  color: ${({ inputValue }) => (inputValue ? 'black' : '#818181')};


`


export const RequestInputBox = styled.div<{inputValue : boolean}>`
  width: 100%;
  height: 52px;
  border-radius : 8px;

  padding : 16px;
  align-items : center;

  background-color : #FFFFFF;

  border: ${({ inputValue }) => (inputValue ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.08)')};

 

`;

export const DialogInput = styled.input`
    border : none;
    height : 20px;
    width : 100%;
    font-size : 16px; 
    outline: none;

    &::placeholder {
      color: #BDBDBD;
    }
    
`;

export const DialogCheckBox = styled.img`
  width : 20px;
  height : 20px;
  cursor : pointer;
  margin-right : 8px;
`





export const ElevatorBtnBox = styled.div`
  padding : 4px;
  display : flex;
  justify-content : space-between;
  flex-direction: row;
  background-color : rgba(0,0,0,0.08);
  height : 52px;
  border-radius : 12px;


`;

export const ElevatorBtn = styled.div`
flex : 1;
cursor : pointer;
width : 100%;
text-align : center;
  padding : 12px 14px ;
  border-radius : 8px;
  display : flex;
  justify-content : center;
  align-items : center;

`;


export const ModalRequestBtn = styled.div`
  width : 100%;
  margin-top : 64px;
  padding : 16px 24px;
  border-radius: 12px;
background: var(--primitive-blue-600, #2A70F0);

display: flex;
justify-content: center;
align-items: center;
gap: 4px;

cursor : pointer;
`;