import { BannerBusanDetreD, BannerBusanDetreM, BannerBusanDetreT, BannerWanggilRoyalcityD, BannerWanggilRoyalcityM, BannerWanggilRoyalcityT, LogoBusanDetre, LogoWanggilRoyalcity, NavBusanDetre, NavWanggilRoyacity } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerBusanDetreD,
    tablet : BannerBusanDetreT,
    mobile : BannerBusanDetreM
  },
  logo : LogoBusanDetre,
  nav : NavBusanDetre,
  name: '부산 에코델타시티 디에트르 그랑루체',
  likes : 1589,
  user: '468,511',
  count: '15,445,432',
  
  a : 35.1581742,
  b : 128.9175124,

  info : [
    {
      data : '부산광역시 강서구 강동동 4277'
    },
    {
      data : '총 972 세대',
    },
    {
      data : '84㎡A, 84㎡B, 84㎡C, 110㎡A, 110㎡B, 110㎡C', 
    },
    {
      data : '대방건설(주)'
    },
    {
      data : '대방하우징(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '최적의 입지',
      desc: '우수한 광역 교통체계, 가덕도신공항(추진 중), 직주근접 핵심 입지, 우수한 교육환경, 대방건설 프리미엄 브랜드'
    },
    {
      icon: Icon3Dsquare,
      title: '커뮤니티',
      desc: '건강한 레저 생활을 할 수 있는 피트니트센터, 스크린 골프연습장, 북카페, 키즈룸 등 디에트르만의 특별한 커뮤니티 시설'
    },
    {
      icon: Icon3Dlife,
      title: '친황경 아파트',
      desc: '전기차충전소 84대(계획), 차량 인식 위치 시스템, 대기전력 차단 시스템, 거실 통창(유리 난간)'
    },
    {
      icon: Icon3Dsecure,
      title: '편리한 시스템',
      desc: '일괄소등, 가스차단 스위치, 무인택배 시스템, 원격제어, 무선 AP 시스템 등'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/e6cf7/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '110A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/85361',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '110A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
          {
            size: '110A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '부산광역시 강서구 강동동 4277'


}