

import { BannerImg, ImageInner, BannerData, StyledBtnBox, BannerButton, BannerPage, TextCenter, BannerButtonRequest } from './Banner.style';
import React, { useCallback,  useState } from 'react';
import { Trans } from 'react-i18next';
import { StyledParagraph } from '@components/Text';

import main from '@src/lang/ko/apartment/main';
import ModalRequest from './ModalReuqest/ModalRequest';
import { Spacing, SpanBlue } from '../Apartment.style';
import { LabelFFF } from '@layouts/Default/components/NavBox/NavBox.style';
import { DisplayH1 } from '@pages/EventPage/Common/Banner/Banner.style';

import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: 'AIzaSyAF1a8WJ-QZuBBwY8VamSK9MlrqkKeMVh4',
  authDomain: 'orbro-webpage.firebaseapp.com',
  projectId: 'orbro-webpage',
  storageBucket: 'orbro-webpage.appspot.com',
  messagingSenderId: '',
  appId: '',
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);



const Banner = () => {


  const handleDownload = (name: string) => {

    getDownloadURL(ref(storage, `gs://orbro-webpage.appspot.com/${name}.pdf`))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          // Create a link element and set the download attribute
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${name}.pdf`;

          // Append the link element to the document and trigger a click
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          URL.revokeObjectURL(a.href);
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img?.setAttribute('src', url);
      })
      .catch((error) => {
      });

  };


  const onRequest = useCallback(() => {
    setModalRequest(false);
  }, [])


  const [isModalRequest, setModalRequest] = useState(false);
  const onClickToggleModalRequest = useCallback(() => {
    setModalRequest(!isModalRequest);
  }, []);


  return (
    <BannerPage>
      <BannerImg img={main.banner.img}/>

      <ImageInner>

        <BannerData>
          
          <DisplayH1 size="medium">
            <Trans i18nKey={`${main.banner.title}`} />
            {<br/>}
            <SpanBlue>
              <Trans i18nKey={`${main.banner.color}`} />
            </SpanBlue>
            <span>
              <Trans i18nKey={`${main.banner.titleTail}`} />
            </span>
          </DisplayH1>

          <Spacing pixel={'8'}/>
          <TextCenter>
            <StyledParagraph size="medium">
              <Trans i18nKey={`${main.banner.desc}`} />
            </StyledParagraph>
          </TextCenter>

          <Spacing pixel={'32'}/>

          <StyledBtnBox>
            <BannerButtonRequest onClick={() => handleDownload('orbrohome')}>
              <LabelFFF size="large">
                <Trans i18nKey={main.banner.download} />
              </LabelFFF>
            </BannerButtonRequest>
            <BannerButton onClick={onClickToggleModalRequest}>
              <LabelFFF size="large">
                <Trans i18nKey={main.banner.request} />
              </LabelFFF>
            </BannerButton>
          </StyledBtnBox>
        </BannerData>
      </ImageInner>


      {isModalRequest && (
        <ModalRequest onClickToggleModal={onRequest}>
        </ModalRequest>
      )}

    </BannerPage>
  )
};

export default Banner;