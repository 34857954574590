import { BannerUnjeongLynnD, BannerUnjeongLynnM, BannerUnjeongLynnT, LogoUnjeongLynn, NavUnjeongLynn } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerUnjeongLynnD,
    tablet : BannerUnjeongLynnT,
    mobile : BannerUnjeongLynnM
  },
  logo : LogoUnjeongLynn,
  nav : NavUnjeongLynn,
  name: '운정신도시 우미린 더 센텀',
  likes : 1841,
  user: '468,511',
  count: '15,445,432',

  a : 37.713805,
  b : 126.726113,

  info : [
    {
      data : '경기도 파주시 동패동 371-8'
    },
    {
      data : '총 418 세대',
    },
    {
      data : '84㎡A, 84㎡B', 
    },
    {
      data : '우미건설(주)'
    },
    {
      data : '(주)명일건설',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '특별한 입지조건',
      desc: '근처 공동주택과 문화공원 예정부지, 도로 방음벽'
    },
    {
      icon: Icon3Dsquare,
      title: '특별한 커뮤니티',
      desc: '실내 골프 연습장, 피트니스 클럽 등 운동시설, 독서실, 작은 도서관'
    },
    {
      icon: Icon3Dlife,
      title: '쾌적한 단지',
      desc: '선호도 높은 주택형 단지, 지상에 주차공간 없는 쾌적한 단지, 전 세대 남향위주'
    },
    {
      icon: Icon3Dsecure,
      title: '입주 환경 관리',
      desc: '청정공기 급배기 시스템, 친황경 마감재, 미세먼지와 CO2 센서 리모콘'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/gssfa/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '5억 5,360',
            competition: '281.38 : 1',
          },
          {
            size: '84B',
            price: '5억 3,450',
            competition: '213.64 : 1',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/91822',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '113A/84A',
            price: '5억 5,360',
            competition: '114.3 : 1',
          },
          {
            size: '113B/84B',
            price: '5억 3,450',
            competition: '94.7 : 1',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://isale.land.naver.com/iSale/Map/#SYDetail?build_dtl_cd=6027257&supp_cd=9040207&DetailType=Complex&SYMap=37.713805,126.726113,16&a=',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84A',
            price: '5억 5,360',
            competition: '-',
          },
          {
            size: '84B',
            price: '5억 3,450',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '경기도 파주시 동패동 371-8'


}