import { BannerGayangHillstateD, BannerGayangHillstateM, BannerGayangHillstateT, LogoGayangHillstate, NavGayangHillstate } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerGayangHillstateD,
    tablet : BannerGayangHillstateT,
    mobile : BannerGayangHillstateM,
  },
  logo : LogoGayangHillstate,
  nav : NavGayangHillstate,
  name: '힐스테이트 가양 더와이즈',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',

  a : 36.3499148,
  b : 127.4404348,

  info : [
    {
      data : '대전광역시 동구 가양동 452-1'
    },
    {
      data : '총 358 세대',
    },
    {
      data : '84㎡, 105㎡, 108㎡, 155㎡', 
    },
    {
      data : '현대건설(주)'
    },
    {
      data : '우리자산신탁(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '동구권 대형개발 수혜',
      desc: '대전역세권개발과 도심융합특구 지정을 비롯하여 약 2만여 세대 주거단지로 변할 동구의 미래가치'
    },
    {
      icon: Icon3Dsquare,
      title: '동부 역세권',
      desc: '대전 도시철도 2호선 동부역(가칭) 완공 시, 더 빨라질 생활과 더 높아질 역세권 프리미엄의 가치'
    },
    {
      icon: Icon3Dlife,
      title: '풍부한 인프라',
      desc: '단지 앞 이마트, CGV, 호텔 등 터미널상권과 단지 안 상업시설을 빠르게 누리는 편리한 원패스 라이프'
    },
    {
      icon: Icon3Dsecure,
      title: '특화설계',
      desc: '전세대 3면 발코니, 높은 천정고(2.5m) 및 스카이가든, 사우나 등 다양한 커뮤니티'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dQCbd/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '105㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '108㎡',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/80170?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '105㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '108㎡',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '105㎡',
            price: '-',
            competition: '-',
          },
          {
            size: '108㎡',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '대전광역시 동구 가양동 452-1'


}