export default {


    title1 : '🎉 ',
    titleBold : 'NUGU 스마트홈 ',
    title2 : '보상판매 🎉',
    title3 : '구축비용 90% 할인',
    desc : 'SKT NUGU 스마트홈 서비스가 종료되어 오브로 홈으로 서비스가 전환되고 있습니다.<br/>오브로 홈은 SKT NUGU 서비스를 승계하여 운영이 가능합니다.<br/>구축 비용을 할인받고 오브로 홈으로 전환하세요.',

    apart : '아파트 단지명 *',
    apartPH : '오브로 아파트',

    name : '고객 이름 (옵션)',
    namePH : '홍길동',

    phone : '고객 연락처 *',
    phonePH : '010-1234-5678',

    email : '이메일 *',
    emailPH : 'email@orbro.io',

    request : '기타 문의(옵션)',
    requestPH : '문의내용',

    elevator : '엘레베이터 제조사 *',
    elevatorPH : '오브로 사',
    
    on : '연동',
    off : '연동 안함',
    btn : 'NUGU 스마트홈 보상 판매 문의'

}