import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";


export const SerivcePage = styled.div`
    width : 100%;
    height : auto;
    display : flex;

    flex-direction : row;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        flex-direction : column;
        text-align : center;
        align-items : center;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        flex-direction : column;
        text-align : center;
    }
`;


export const ServiceLeftSide = styled.div`
    flex : 1;
    display : flex;
    flex-direction : column;
     
    justify-content : center;

    padding : 98px 48px 98px 0;

    @media (max-width : 1052px) {
        padding : 64px 24px 64px 0;
    }

    @media (max-width : 960px) {
        padding : 0;
    }


`;


export const Appbox = styled.div`

display : flex;
flex-direction : row;
gap : 8px;
margin-top : 32px;
width : 100%;
@media (max-width : 960px){
    display : flex;
    justify-content : center; 
}

`;

export const AppBtn = styled.img<{width : string}>`
display : flex;
height : 40px;
cursor : pointer;
${({width})=> width&& `width:${width}px;`}

`;



export const Falldown = styled.div`
    display : flex;
    flex-direction : column;
    gap : 24px;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        display : none;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : none;
    }
    
`;

export const FalldownBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 16px;
    height : fit-content;

    
`;

export const FallTextBox = styled.div`
display : flex;
flex-direction : column;
cursor : pointer;
gap : 4px;

@media (max-width : 960px) {
    width : 100%;
    margin : 0 8px;

    animation: in 4s linear forwards; 
    animation: out 4s linear forwards; 

    @keyframes in {
        from {
            transform: translateX(100%); 
        }
        to {
            transform: translateX(0%); 
        }
        @keyframes out {
        from {
            transform: translateX(0%); 
        }
        to {
            transform: translateX(100%); 
        }
    }
}

@media (max-width : 610px) {
    height: 78px;
}

`



export const ProgressBar = styled.div<{h : string}>`

  display : flex;
  width : 2px;
  background-color : rgba(0,0,0,0.08);

  ${({h})=> h && `height : ${h}px;`}
 
`;


export const ProgressBar2 = styled.div`

    position : absolute;
    z-index : 3;
    width: 2px;
    height : 56px;

    animation: fillProgress 4s linear forwards; 

        @keyframes fillProgress {
        from {
            background-color : #000;
            height: 0 
        }
        to {
            background-color: #000;
            height: 56px; 
        }
    }
`;
export const ServiceRightSide = styled.div`
    width : 100%;
    display : flex;
    object-fit : contain;
    
    flex : 1;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        max-height : 540px;
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        max-height : 440px;
    }

`

export const RightImg = styled.img`

    width : 100%;
    display : flex;
    object-fit : cover;

    height : auto;
    aspect-ratio : 618 / 680 ;
    @media (max-width : 960px) {
        object-fit : contain;
    }

`



export const SelectBox = styled.div`

    @media (min-width : 961px) {
        display : none;
    }
    @media (max-width : 960px){
        display : flex;
        flex-direction: row;
        justify-content : space-between;
        width : 100%;
        text-algin : center;
        margin-bottom : 32px;
    }
`;


export const SelectBtn = styled.div<{ direction: number }>`
    cursor : pointer;
    width: 56px;
    height : 56px;
    display : flex;
    object-fit : contain;
    justify-content : center;
    align-items : center;

    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        ${({ direction }) => direction === 0 ? 'margin-left:32px' : 'margin-right : 32px'};
    }
    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        margin : auto;
    }
    
    & img { 
        width : 56px;
        height : 56px;
        object-fit : contain;
    }
`;



