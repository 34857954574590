import { StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading, StyledParagraph } from "@components/Text";
import { CommonState } from "@redux/reducers/commonReducer";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { ExamImg, ExamImgBox, ExamLayout, ExamLogo, ExamLogobox, StyledOurPartnersLogo, StyledOurPartnersLogos } from "./Exam.style";

import main from "@src/lang/ko/apartment/main";
import { Spacing, SpanBlue } from "../Apartment.style";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";
const Exam = (): React.ReactElement => {
  // Redux
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  const data = main.exam.lineup;
  const extendedData = [...data, ...data, ...data];


  return (
    <ExamLayout>
      <StyledGridContainer>

        <StyledDisplay size="small">
          <Trans i18nKey={`${main.exam.title1}`} />
        </StyledDisplay>
        <DisplayH2 size="small">
          <SpanBlue>
            <Trans i18nKey={`${main.exam.titleBold}`} />
          </SpanBlue>
          <span>
            <Trans i18nKey={`${main.exam.title2}`} />
          </span>
        </DisplayH2>
      </StyledGridContainer>

      <StyledOurPartnersLogos>
        {extendedData.map((logo, index) => (
          <ExamImgBox key={index}>
            <ExamImg src={logo.img} alt="orbrohome service"/>
            <ExamLogobox>
              <ExamLogo src={logo.logo} alt="orbrohome service"/>
            </ExamLogobox>
            <Spacing pixel="8"/>
            <StyledHeading size="xxsmall">
              <Trans i18nKey={`${logo.name}`} />
            </StyledHeading>
            <StyledParagraph size="medium">
              <Trans i18nKey={`${logo.desc}`} />
            </StyledParagraph>
          </ExamImgBox>
        ))}
      </StyledOurPartnersLogos>

    </ExamLayout>
  );
};

export default Exam;