import React, { useState, useEffect, useCallback } from 'react';
import { BottomFix, BottomMenuBtn, BottomMenuSection } from './BottomMenu.style';
import { StyledLabel } from '@components/Text';
import { Trans } from 'react-i18next';
import ModalRequest from '../Banner/ModalReuqest/ModalRequest';
import ModalEvent from '../ModalEvent/ModalEvent';
import { LabelBlue, LabelFFF } from '@layouts/Default/components/NavBox/NavBox.style';

const BottomMenu = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    // Function to update the position of the menu based on scroll
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // You can adjust the threshold value as needed
      const isScrollingDown = scrollTop > 50;

      setMenuVisible(isScrollingDown);
    };

    // Attach the event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const [isModalEvent, setModalEvent] = useState<boolean>(false);
  const onEvent = useCallback(() => {
    setModalEvent(false);
  }, [])
  const onClickToggleModalEvent = useCallback(() => {
    setModalEvent(!isModalRequest);
  }, []);



  const [isModalRequest, setModalRequest] = useState<boolean>(false);
  const onRequest = useCallback(() => {
    setModalRequest(false);
  }, [])
  const onClickToggleModalRequest = useCallback(() => {
    setModalRequest(!isModalRequest);
  }, []);


  return (
    <>
      <BottomFix>
        <BottomMenuSection>
          <BottomMenuBtn onClick={() => onClickToggleModalEvent()} color={'#2A70F0'}>
            <LabelFFF size={'large'}>
              <Trans i18nKey={'🎉 NUGU 스마트홈 보상판매'} />
            </LabelFFF>
          </BottomMenuBtn>
          <BottomMenuBtn onClick={() => onClickToggleModalRequest()} color='#FFF'>
            <LabelBlue size={'large'}>
              <Trans i18nKey={'📞 서비스 도입 문의 하기'} />
            </LabelBlue>
          </BottomMenuBtn>


          {isModalEvent && (
            <ModalEvent onClickToggleModal={onEvent}>
            </ModalEvent>
          )}

          {isModalRequest && (
            <ModalRequest onClickToggleModal={onRequest}>
            </ModalRequest>
          )}

        </BottomMenuSection>
      </BottomFix>
    </>
  );
};

export default BottomMenu;
