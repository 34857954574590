import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { TextCenterColumnFlex } from "@pages/Apartment/Banner/Banner.style";
import common from "@src/lang/ko/event/common";
import React from "react";
import { Trans } from "react-i18next";
import { InfoChart, InfoChartR, InfoChartl, InfoGrid1, InfoLogo, InfoPage } from "./Info.style";

import { HeadingColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import { DisplayH2 } from "../Banner/Banner.style";

import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";

const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}



interface apart {
  apartName: string;
}

const Info: React.FC<apart> = ({ apartName }) => {

  const path = list[apartName];


  return (
    <InfoPage>
      <StyledGridContainer>
        <TextCenterColumnFlex>
          <InfoLogo>
            <img src={path.logo} alt={`${apartName} apartment logo`}/>
          </InfoLogo>
          <DisplayH2 size="small">
            <span>
              <Trans i18nKey={`${apartName}.name`} />
            </span>
            <SpanBlue>
              <Trans i18nKey={' ' + common.info.title} />
            </SpanBlue>
          </DisplayH2>
        </TextCenterColumnFlex>

        <Spacing pixel="64"/>

        <StyledGridRow>
          <InfoGrid1 desktop={1} tablet={0} mobile={0}>
          </InfoGrid1>

          <StyledGridColumn desktop={10} tablet={8} mobile={4}>
            {common.info.chart.map((text, i) => (
              <>
                <InfoChart number={i}>
                  <InfoChartl>
                    <HeadingColor size="xsmall" color="#818181">
                      <Trans i18nKey={text.data} />
                    </HeadingColor>
                  </InfoChartl>
                  <InfoChartR>
                    <HeadingColor size="xsmall" color="#818181">
                      <Trans i18nKey={`${apartName}.info.${i}.data`} />
                    </HeadingColor>
                  </InfoChartR>
                </InfoChart>

              </>))}
          </StyledGridColumn>
        </StyledGridRow>

      </StyledGridContainer>
    </InfoPage>
  )
}


export default Info;