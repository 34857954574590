import { BannerGagyeonIparkD, BannerGagyeonIparkM, BannerGagyeonIparkT, LogoGagyeonIpark, NavGagyeonIpark } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerGagyeonIparkD,
    tablet : BannerGagyeonIparkT,
    mobile : BannerGagyeonIparkM,
  },
  logo : LogoGagyeonIpark,
  nav : NavGagyeonIpark,
  name: '가경 아이파크',
  likes : 1391,
  user: '418,511',
  count: '15,425,432',

  a : 36.6153501,
  b : 127.436406,

  info : [
    {
      data : '충청북도 청주시 흥덕구 가경동 330번지 일원'
    },
    {
      data : '총 946 세대',
    },
    {
      data : '84㎡·99㎡·101㎡·116㎡', 
    },
    {
      data : 'HDC현대산업개발(주)'
    },
    {
      data : 'HDC현대산업개발(주)',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '다양한 인프라로 더 편하게',
      desc: '홈플러스, 롯데마트, NC백화점, 현대백화점, 단지 앞 대형공원(예정)과 가경공원 등 풍부한 생활편의시설 인접'
    },
    {
      icon: Icon3Dsquare,
      title: '브랜드 시티로 더 특별하게',
      desc: '1~6단지 총 4,624세대 브랜드 시티의 품격과 자부심을 한층 더 높여줄 전세대 중대형 특화설계'
    },
    {
      icon: Icon3Dlife,
      title: '도심속 쾌적한 힐링라이프',
      desc: '단지 앞 대형공원(예정), 가경공원 등 탁 트인 전망의 힐링공간, 청주의 주거문화를 이끌어가는 아이파크 브랜드 시티의 자부심'
    },
    {
      icon: Icon3Dsecure,
      title: '도보통학권으로 더 안전하게',
      desc: '서경초·중, 서현중, 충북사대부중·고, 서원고, 가로수 도서관 등이 인접한 우수한 교육환경 조성'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/fAG6e/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84타입',
            price: '4억 3,700',
            competition: '발표 예정',
          },
          {
            size: '99타입',
            price: '5억 400',
            competition: '발표 예정',
          },
          {
            size: '101타입',
            price: '5억 2,000',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/89495?checking_subscription=true',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '84타입',
            price: '4억 3,700',
            competition: '발표 예정',
          },
          {
            size: '99타입',
            price: '5억 400',
            competition: '발표 예정',
          },
          {
            size: '116타입',
            price: '5억 9,400',
            competition: '발표 예정',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
          {
            size: '-',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '충청북도 청주시 흥덕구 가경동 330번지 일원'


}