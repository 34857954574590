import { BannerWanggilRoyalcityD, BannerWanggilRoyalcityM, BannerWanggilRoyalcityT, LogoWanggilRoyalcity, NavWanggilRoyacity } from "@assets/images/apartment/list";
import { Icon3Dlife, Icon3Dmap, Icon3Dsecure, Icon3Dsquare } from "@assets/images/icon";

export default {

  img : {
    desktop : BannerWanggilRoyalcityD,
    tablet : BannerWanggilRoyalcityT,
    mobile : BannerWanggilRoyalcityM
  },
  logo : LogoWanggilRoyalcity,
  nav : NavWanggilRoyacity,
  name: '왕길 로얄파크 씨티 푸르지오',
  likes : 1589,
  user: '468,511',
  count: '15,445,432',
  
  a : 37.5959853,
  b : 126.6497903,

  info : [
    {
      data : '인천광역시 서구 왕길동 133-3'
    },
    {
      data : '총 21,313 세대',
    },
    {
      data : '59㎡A, 59㎡B, 74㎡A, 74㎡B, 84㎡A, 84㎡B, 84㎡C, 99㎡', 
    },
    {
      data : '대우건설(주)'
    },
    {
      data : 'DK퍼스트',
    },
  ],
  point: [
    {
      icon: Icon3Dmap,
      title: '로열 인프라',
      desc: '왕길역세권의 스피드, 더블IC, 서울까지 10분대, 산업단지와 직주근접, 안심 교육환경, 쇼핑, 문화, 생활의 중심'
    },
    {
      icon: Icon3Dsquare,
      title: '로열 프리미엄',
      desc: '대한민국 리조트특별시, 5세대 하이엔드 건축 설계 및 압도적 문주 특화, 커뮤니티 특화'
    },
    {
      icon: Icon3Dlife,
      title: '로열 시그니처',
      desc: '유럽식 분수와 수경공간, 웅장한 스케일, 단지 내 테마파크, 조경특화'
    },
    {
      icon: Icon3Dsecure,
      title: '로열 투자가치',
      desc: '전매제한 6개월, 실거주 의무없음, 중도금 무이자, 세대원 청약가능, 재당첨 제한없음, 주택수 무관 1순위 청약가능'
    },
  ],

  price: {
    company: [
      {
        link : 'https://hogangnono.com/apt/dXVb7/0',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '74A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'https://www.zigbang.com/home/apt/danjis/84860',
        data: [
          {
            size : '공급/전용',
            price : '분양가',
            competition : '경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '74A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },
      {
        link : 'no',
        data: [
          {
            size : '타입',
            price : '분양가',
            competition : '1순위 경쟁률',
          },
          {
            size: '59A',
            price: '-',
            competition: '-',
          },
          {
            size: '74A',
            price: '-',
            competition: '-',
          },
          {
            size: '84A',
            price: '-',
            competition: '-',
          },
        ]
      },

    ]

  },

  map: '인천광역시 서구 왕길동 133-3'


}