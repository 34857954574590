import React, { useMemo } from "react";
import { TextCenterColumn } from "../Common.style";
import { Trans } from "react-i18next";
import common from "@src/lang/ko/event/common";
import { StyledDisplay, StyledHeading } from "@components/Text";
import { BannerButton, BannerImg, DisplayH1 } from "./Banner.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayColor, Spacing, SpanBlue } from "@pages/Apartment/Apartment.style";
import { LabelFFF } from "@layouts/Default/components/NavBox/NavBox.style";

import munjeonghillstate from "@src/lang/ko/event/list/munjeonghillstate";
import uamharrington from "@src/lang/ko/event/list/uamharrington";
import domaforena from '@src/lang/ko/event/list/domaforena';
import thepole from '@src/lang/ko/event/list/thepole';
import unjeonglynn from '@src/lang/ko/event/list/unjeonglynn';
import uiwangdesian from '@src/lang/ko/event/list/uiwangdesian';
import seosanipark from "@src/lang/ko/event/list/seosanipark";
import osansegyoparagon from "@src/lang/ko/event/list/osansegyoparagon";
import wanggilroyalpark from "@src/lang/ko/event/list/wanggilroyalpark";
import busandetre from "@src/lang/ko/event/list/busandetre";
import wonbonghildesheim from "@src/lang/ko/event/list/wonbonghildesheim";
import unamfirstiche from "@src/lang/ko/event/list/unamfirstiche";
import mapourbanpiece from "@src/lang/ko/event/list/mapourbanpiece";

import jemulpoparkmaison from "@src/lang/ko/event/list/jemulpoparkmaison";
import gagyeongipark from "@src/lang/ko/event/list/gagyeongipark";
import raemianreventus from "@src/lang/ko/event/list/raemianreventus";
import geomdanjeil from "@src/lang/ko/event/list/geomdanjeil";
import maegyopellucid from "@src/lang/ko/event/list/maegyopellucid";
import zenithcentral from "@src/lang/ko/event/list/zenithcentral";
import tangjeonginfinity from "@src/lang/ko/event/list/tangjeonginfinity";
import cheongjuparklane from "@src/lang/ko/event/list/cheongjuparklane";
import gayanghillstate from "@src/lang/ko/event/list/gayanghillstate";
import weparkilgok from "@src/lang/ko/event/list/weparkilgok";
import daunfirstheim from "@src/lang/ko/event/list/daunfirstheim";
import dongraelottecastle from "@src/lang/ko/event/list/dongraelottecastle";

const list = {
  munjeonghillstate,
  uamharrington,
  domaforena,
  thepole,
  unjeonglynn,
  uiwangdesian,
  seosanipark,
  osansegyoparagon,
  wanggilroyalpark,
  busandetre,
  wonbonghildesheim,
  unamfirstiche,
  mapourbanpiece,

  jemulpoparkmaison,
  gagyeongipark,
  raemianreventus,
  geomdanjeil,
  maegyopellucid,
  zenithcentral,
  tangjeonginfinity,
  cheongjuparklane,
  gayanghillstate,
  weparkilgok,
  daunfirstheim,
  dongraelottecastle

}


interface apart {
  apartName: string;
  index : number;
}

const Banner: React.FC<apart> = ({ apartName, index }) => {


  const path = useMemo(() => list[apartName]?.img || '', []);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  return (
    <>

      <Spacing pixel="128"/>
      <TextCenterColumn>
        {index === 1 &&
        <>
          <DisplayH1 size="medium">
            <Trans i18nKey={`${apartName}.name`} />
          </DisplayH1>
          <DisplayColor size="medium" color='#2A70F0'>
            <Trans i18nKey={common.banner.title} />
          </DisplayColor>
        </>}

        {index === 2 &&
        <>
          <DisplayH1 size="medium">
            <span>
            <Trans i18nKey={common.firework + ' '} />
            </span>
            <span>
            <Trans i18nKey={`${apartName}.name`} />
            </span>
            <span>
            <Trans i18nKey={' 아파트 '+common.nav.event + ' ' + common.firework} />
            </span>
          </DisplayH1>
          <DisplayColor size="medium" color='#2A70F0'>
            <Trans i18nKey={`원패스 시세확인`} />
          </DisplayColor>
        </>}

        {index === 3 &&
        <>
          <StyledDisplay size="medium">
            <Trans i18nKey={`${apartName}.name`} />
            <span>
            <Trans i18nKey={' 아파트 '} />
            </span>
          </StyledDisplay>
          <StyledDisplay size="medium" >
            <SpanBlue>
            <Trans i18nKey={`원패스 시세확인`} />
            </SpanBlue>
          </StyledDisplay>
        </>}

        <Spacing pixel="8"/>
        <StyledHeading size="xxsmall">
          <Trans i18nKey={common.banner.desc} />
        </StyledHeading>
      </TextCenterColumn>


      <BannerButton href="/" target="_blank" rel="noopener">
        <LabelFFF size="large">
          <Trans i18nKey={common.banner.button} />
        </LabelFFF>
      </BannerButton>
      <Spacing pixel="64"/>
      <BannerImg>
        <img src={path[layoutType]} alt={`${apartName} apartment Image`}/>
      </BannerImg>

    </>
  )
}


export default Banner;