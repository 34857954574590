import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";


export const SecureImgSection = styled.div`
  width : 100%;
  margin-top : 64px;
  display : flex;
  flex-direction : row;
  border-radius : 16px;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    display : flex;
    flex-direction : column;
    gap : 16px;
  }

`;

export const SecureImgL =styled.div<{img : string}>`
flex 1;
width : 100%;
display : flex;
flex-direction : column;
background-color : #FFF1F0;

background-size : cover;
background-position : center;

max-height : 520px;
aspect-ratio : 616 / 520;

${({img})=> img&& `background-image : url(${img});`}

${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  max-height : 520px;
  aspect-ratio : 456 / 520;
}
${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  max-height : 100%;
  aspect-ratio : 361 / 420;
}

`;


export const SecureImgR =styled.div<{img:string}>`
flex 1;
width : 100%;
display : flex;
flex-direction : column;
background-color : #EAF1FD;

background-size : cover;
background-position : center;
max-height : 520px;
aspect-ratio : 616 / 520;

${({img})=> img&& `background-image : url(${img});`}

${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
  max-height : 520px;
  aspect-ratio : 456 / 520;
}
${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
  max-height : 100%;
  aspect-ratio : 361 / 420;
}

`;


export const TextBox = styled.div`
padding : 32px;
display : flex;
flex-direction : column;
text-align : center;

`;

export const ImgBox = styled.img`

margin-bottom : 20px;
object-fit : contain;
width : 100%;

`;