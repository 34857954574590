import { FlexStyle } from "@assets/styled/flex";
import { SCREEN_BREAKPOINT, StyledGridContainer } from "@components/Grid/Grid.style";
import { StyledLabel } from "@components/Text";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MenuProps } from "@layouts/Default/core/model";
import { CSSProperties } from "react";

// 페이지 상단 고정
// position: ${({ isServicePage }) => (isServicePage ? 'absolute' : 'fixed')};

interface StyledNavBoxProps {
  isServicePage: boolean;
  position?: CSSProperties['position'];
  isGNBFixed: boolean;
}
export const StyledNavBox = styled(FlexStyle)<StyledNavBoxProps>`
  z-index: 6;
  width: 100%;
  height: 64px;
 
  margin-top : 80px;
  background-color : transparent;

  ${({ position }) => position && `position: ${position};`}
  
  
  justify-content: center;
  align-items: center;
  
  left: 0;

  top: ${({ isGNBFixed }) => (isGNBFixed ? '-100px' : '0')}; /* 기본적으로 숨겨져 있도록 위치 설정 */
  ${({ isGNBFixed, theme }) =>
    isGNBFixed &&
    css`
      position: fixed;
      background-color: #FFFFFF;
      
      backdrop-filter: blur(50px);
      transform: translateY(100px);
      transition: transform .4s;
      border-bottom : 1px solid rgba(0,0,0,0.08);
  `}


  & * {
    font-family: Pretendard !important;
  }
`;

export const StyledHeaderContainer = styled(StyledGridContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  
`;

export const NavContainer = styled.div`
  height : 30px;
  display : flex;
  justify-content : center;
  object-fit : contain;
`;

export const StyledHeaderLogo = styled.img`
  cursor: pointer;
  width: 130px;
  height : 100%;
  aspect-ratio: auto 130 / 64;

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {

  }
`;



export const StyledMenus = styled(FlexStyle)`
  flex: 1;
  
  display : flex;
  justify-content : center;
  
  @media (max-width: 768) {
    visibility: hidden;
  }

`;

export const StyledMenu = styled(FlexStyle)<MenuProps>`
  padding : 0 12px;
  
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  border-radius: 8px;
  height : 40px;

  border-radius: 8px;
  align-items: center;
  user-select: none;
  position: relative;

  ${({ isLast }) => isLast ? `margin : 0px;` : `margin-right : 8px;`}

  & > img {
    margin-left: 4px;
    padding-bottom: 3px;
  }

  &:hover {
    background: rgba(0,0,0,0.03);
    cursor: pointer;
  }


  color: ${({ active, theme }) => active ? theme.CONTENT_TERTIARY : theme.CONTENT_PRIMARY};
  & > a {
    color: inherit;
  }
`;

// NavBox 클릭 범위 넓히기.


export const PricingBtn = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
  background-color: #2A70F0;
  cursor : pointer;
`;


export const StyledHeaderButton = styled.a`
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  line-height: 1.14;
  border-radius: 8px;
  color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
  background-color: #2A70F0;
`;

export const StyledNavTopBox = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => (theme.BACKGROUND_PRIMARY)};
`;


export const StyledNavText = styled(StyledLabel)`
  width: 100%;
  color : white;
  display : flex;
  align-items : center;

  padding: 12px;

  border-radius: 8px;
  height : 40px;
  
  &:hover {
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
  }

`;




export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  
`;



export const StyledGlobal = styled(FlexStyle)`
  width: auto;
  height: 64px;
  cursor : pointer;
  position : relative;
  
  align-items: center; 
  display : flex;
  flex-direction : row; 


`


export const StyledGlobalTextBox = styled.div`
  margin-left : 4px;
  margin-top : 2px;
  height : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  
`


export const StyledGlobalText = styled(StyledLabel)`
  color : #818181;
`

export const StyledGlobalImg = styled(FlexStyle)`
  margin-right : 28px;
  padding : 20px 16px;
  width: 56px;
  height: 64px;
  cursor : pointer;
  align-items: center;
  position: relative;
  
`
export const ImgCursor = styled.img`
  cursor : pointer
`;

export const LabelFFF = styled(StyledLabel)`
  color : #fff;
`;

export const LabelBlue = styled(StyledLabel)`
  color : #2A70F0;
`;