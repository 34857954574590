import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import { CommonText } from "@components/Text/CommonText.style";
import styled from "@emotion/styled";



export const PricePage = styled.div`


    padding-top : 128px;


    ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
        padding-top : 96px;
    }

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        padding-top : 64px;
    }

`;

export const PriceBorderBottom = styled.div`
    padding-bottom : 64px;
    border-bottom : 1px solid rgba(0,0,0,0.08);
`;


export const PriceChartSection = styled.div`

    margin-top : 64px;

    display : grid;
    grid-template-columns : repeat(3, 1fr);
    width : 100%;
    gap : 32px;


    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
        display : flex; 
        flex-direction :column;
    }

`;

export const PriceBox = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 20px;
    border: 1px solid var(--border-translucent-light, rgba(0, 0, 0, 0.08));
    background: var(--background-secondary-light, #F8F8F8);
`;


export const PriceImgText = styled.div`
    display : flex;
    flex-direction : row;
    gap : 16px;

    & img {
        width : 70px;
        height : 70px;
        object-fit : contain;
        border-radius : 16px;
    }
`;

export const PriceTextBox = styled.div`

    padding : 5px 0;
    gap : 8px;
    display : flex;
    flex-direction : column;

`;


export const Showmore = styled.a<{text:string}>`
    width : 100%;
    display : flex;
    flex-direction : row;
    gap : 4px;
    object-fit :contain;
    align-items : center;

    ${({text})=> text === 'no' ? 'cursor:text; pointer-events:none;' : 'cursor:pointer; pointer-events:auto;'}
    
    & img {
        width : 20px;
        height : 20px;
    }
`;


export const PriceBottomSection = styled.div`
    margin-top : 16px;
    display : flex;
    flex-direction : column;
    width : 100%;

`;

export const DataBox = styled.div<{n : number}>`
    display : grid;
    grid-template-columns : repeat(3, 1fr);
    width : 100%;
    padding : 12px 0;

    ${({n})=> n === 0 ? 'border-bottom : 1px solid rgba(0,0,0,0.08);' : ''}
`;

export const DataRowBox =styled.div`
    
    display : flex;
    flex-direction : row;

`;


export const HeadingXXXXsmall = styled(CommonText)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color : #818181;

`;
