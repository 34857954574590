import {  DiscountCardD, DiscountCardM, DiscountCardT, Hogang, Naver, Zigbang } from "@assets/images/apartment/event";

export default {

    firework : '🎉',
    thumb : '👍',
    more : '더 알아보기',
    nav : {
        event : '분양 이벤트',
        onepass : '원패스 ',
        sales : "할인 판매중",
    },
    banner : {
        title : '아파트 원패스',
        desc : '프리미엄 아파트에 어울리는 품격있는 선택',
        button : '우리 아파트 견적 문의하기'
    },
    event : {
        title : ' 아파트 오브로 홈 설치비용 ',
        discount : '50% 할인 ',
        tail : '이벤트',
        img : {
            desktop : DiscountCardD,
            tablet : DiscountCardT,
            mobile : DiscountCardM
        },
        card: {
            tag : '입주 축하',
            price : '설치비 ',
            percent : '50%',
            discount : '할인',
            desc : '오브로 홈 공식 페이지에서 오브로 홈 설치 신청을 하면,<br/>설치비 50% 할인!'
        }
    },
    request : {
        title1 : '좋아요 눌러서 ',
        title2 : '우리 아파트 원패스 설치 요청하기',
        desc : `'좋아요'를 눌러주시면 오브로 홈에서 순차적으로 아파트에 요청해요.`,
        button : '좋아요',
        user : '누적 사용자',
        count : '출입 횟수'
    },
    info : {
        title : '개요',

        chart : [
            {
                data : '위치'
            },
            {
                data : '세대수'
            },
            {
                data : '전용 면적'
            },
            {
                data : '시공사'
            },
            {
                data : '시행사'
            },
            
        ]
        
    },
    point : ' 특징',
    
    price : {
        title : ' 분양가 시세확인',
        company : [
            {
                icon : Hogang,
                title : '호갱노노',
            },
            {
                icon : Zigbang,
                title : '직방',
            },
            {
                icon : Naver,
                title : '네이버 부동산',
            }
        ]
    },

    map : '위치 확인',
    
    
    
}