import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import main from "@src/lang/ko/apartment/main";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { OnepassBox, OnepassCard } from "./Onepass.style";
import { HeadingColor, Layout128to96, Spacing, SpanBlue } from "../Apartment.style";
import { useSelector } from "react-redux";
import { CommonState } from "@redux/reducers/commonReducer";
import { DisplayH2 } from "@pages/EventPage/Common/Banner/Banner.style";



const Onepass = () => {


  const [isTablet, setTablet] = useState<boolean>(false);
  const [isMobile, setMobile] = useState<boolean>(false);
  const [mapCount, setCount] = useState<number>(3);
  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);

  useEffect(() => {

    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth < 768) {
        setMobile(true);
        setTablet(false);
        setCount(3);
      } else if (innerWidth < 960) {
        setMobile(false);
        setTablet(true);
        setCount(2);
      } else {
        setMobile(false);
        setTablet(false);
        setCount(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <Layout128to96>
      <StyledGridContainer>
        <DisplayH2 size="small">
          <Trans i18nKey={main.onepass.title1} />
        </DisplayH2>
        <StyledDisplay size="small">
          <span>
            <Trans i18nKey={main.onepass.title2} />
          </span>
          <SpanBlue>
            <Trans i18nKey={main.onepass.titleBold} />
          </SpanBlue>
          <span>
            <Trans i18nKey={main.onepass.title3} />
          </span>
        </StyledDisplay>

        <Spacing pixel="64" />

        <StyledGridRow >
          {main.onepass.box.map((data, i) => (
            <>
              <>
                {i < mapCount &&
                  <StyledGridColumn desktop={4} tablet={4} mobile={4} key={i}>
                    <OnepassBox>
                      <OnepassCard img={main.onepass.box[i * 2].img[layoutType]} length={main.onepass.box[i * 2].height[layoutType]}>
                        <HeadingColor size="xxxxsmall" color={"#646464"}>
                          <Trans i18nKey={main.onepass.box[i * 2].tag} />
                        </HeadingColor>
                        <Spacing pixel="8" />
                        <StyledHeading size="medium" >
                          <Trans i18nKey={main.onepass.box[i * 2].title} />
                        </StyledHeading>
                      </OnepassCard>
                      <OnepassCard img={main.onepass.box[i * 2 + 1].img[layoutType]} length={main.onepass.box[i * 2 + 1].height[layoutType]}>
                        {i === 1 ?
                          <><HeadingColor size="xxxxsmall" color={ '#FFF' }>
                            <Trans i18nKey={main.onepass.box[i * 2 + 1].tag} />
                          </HeadingColor>
                            <Spacing pixel="8" />
                            <HeadingColor size="medium" color={'#FFF'}>
                              <Trans i18nKey={main.onepass.box[i * 2 + 1].title} />
                            </HeadingColor></>
                          :
                          <><HeadingColor size="xxxxsmall" color={'#646464'}>
                            <Trans i18nKey={main.onepass.box[i * 2 + 1].tag} />
                          </HeadingColor>
                            <Spacing pixel="8" />
                            <HeadingColor size="medium" color={'#000'}>
                              <Trans i18nKey={main.onepass.box[i * 2 + 1].title} />
                            </HeadingColor></>}

                      </OnepassCard>
                      {isTablet && i < 2 &&
                        <OnepassCard img={main.onepass.box[i * 1 + 4].img[layoutType]}
                          length={main.onepass.box[i * 1 + 4].height[layoutType]}>
                          <HeadingColor size="xxxxsmall" color={"#646464"}>
                            <Trans i18nKey={main.onepass.box[i * 1 + 4].tag} />
                          </HeadingColor>
                          <Spacing pixel="8" />
                          <StyledHeading size="medium">
                            <Trans i18nKey={main.onepass.box[i * 1 + 4].title} />
                          </StyledHeading>
                        </OnepassCard>
                      }

                    </OnepassBox>

                  </StyledGridColumn>
                }
              </>

            </>
          ))}
        </StyledGridRow>

      </StyledGridContainer>

    </Layout128to96>
  )
}


export default Onepass;