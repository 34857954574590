import { SCREEN_BREAKPOINT, StyledGridColumn, StyledGridRow } from "@components/Grid/Grid.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import styled from "@emotion/styled";


export const ElevatorPage = styled.div`
  width : 100%;
  background-color : #EAF1FD;

  padding : 128px 0;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 96px 0;
  }

`;


export const StyledOurPartners = styled.div`
  width: 100%;
  padding: 128px 0 132px;
  background-color: ${({ theme }) => theme['BACKGROUND_SECONDARY']};

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    padding: 96px 0;
  }

  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    padding: 96px 0;
  }
`

export const StyledOurPartnersHeading = styled(StyledHeading)`
  text-align: center;
`;

export const StyledOurPartnersDisplay = styled(StyledDisplay)`
  text-align: center;
  margin-top: 16px;
`;

export const StyledOurPartnersLogos = styled(StyledGridRow)<{count : number}>`
  margin-top: 64px;
  
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap : 32px;
  max-height : 60px;

  ${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY} {
    grid-template-columns: repeat(5, 1fr);
    max-height : 60px;
  }
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    ${({count}) => count && `grid-template-columns: repeat(${count}, 1fr);`}
    gap : 12px;
    margin-top : 12px;
    max-height : 40px;
  }
`;


export const LogoWrapper = styled.div`
  width : 100%;
  
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 40px;
  }
`;

export const LogoImg = styled.img`
  width : 100%;
  height : 60px;
  object-fit : contain;
  aspect-ratio : auto 220 / 60 ;
  ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
    height : 40px;
    width : 100%;
  }
`


export const StyledOurPartnersLogo = styled(StyledGridColumn)`
  & img { 
    max-width: 100%;
    max-height: 60px;
    margin: 0 auto;

    ${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY} {
      max-height: 65px;
    }
  }
`;