import { SCREEN_BREAKPOINT } from "@components/Grid/Grid.style";
import styled from "@emotion/styled";


export const OnepassBox = styled.div`

display : flex;
flex-direction : column;
gap : 32px;

${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    gap : 16px;
}

`;



export const OnepassCard = styled.div<{ img : any, length: number }>`

display : flex;
flex-direction : column;
padding : 32px;

width : auto;
height : auto;


background-size : cover;
background-position : center;
${({img}) => img && `background-image : url(${img});`}
${({ length }) => `aspect-ratio : 390 / ${length};`}


${SCREEN_BREAKPOINT.TABLET_MEDIA_QUERY}{
    height : auto;
    ${({ length }) => `aspect-ratio : 440 / ${length};`}
    
}

${SCREEN_BREAKPOINT.MOBILE_MEDIA_QUERY}{
    height : auto;
    aspect-ratio : 361 / 420;
    margin : 0;
}

`;
